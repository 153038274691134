export default {
    //REGULAR EXPRESSIONS
    //#region
    re1: 'Sunt interesat',
    ty:'Mulțumesc!',
    //#endregion

    //SITES
    //#region
    sites: [
      {text: 'Pagina principală', url: '', metaName:'',metaContent:''},
      {text: 'Producție de flacoane', url: 'flacoane', metaName:'',metaContent:''},
      {text: 'Producție de cupe', url: 'cupe', metaName:'',metaContent:''},
      {text: 'Producție de mașini de suflat flacoane', url: 'masini-de-suflat-flacoane',metaName:'',metaContent:''},
      {text: 'Accesorii', url: 'accesorii',show: false, metaName:'',metaContent:''},
      {text: 'protecția datelor', url: 'datelor', right:true, metaName:'',metaContent:''},
      {text: 'Plângeri', url: 'plangeri', right:true, metaName:'',metaContent:''},
    ],
    //#endregion

    //MAIN VIEW
    //#region
    h1: 'Treizeci de ani, ',
    h2: 'experiența ',
    h3: 'garanției',
    h4: 'Soluțiile noastre tehnologice moderne servesc confortului, productivității și rentabilității clienților noștri, la un preț competitiv, pentru mulți ani.',
    h5: 'Mașină instalată',
    h6: 'Ani de experiență',
    h7: 'Categorie de produse',
    h8: 'Angajat',
    hb1: 'Producție de mașini de suflat flacoane',
    hb2: 'Cel mai mare producător de mașini de suflat flacoane din Ungaria',
    hb3: 'Producție de flacoane',
    hb4: 'Expert în proiectarea flacoanelor PET personalizate',
    hb5: 'Producție de accesorii',
    hb6: 'Cupe profesionale produse la noi',
    hb7: 'Citește mai mult →',
    h9: 'Solicitați o ofertă!',
    au1: 'Despre noi',
    au2: 'soluții la nivel de industrie',
    au3: 'Experiență profesională cuprinzătoare în industria ambalajelor din plastic, fabricația de mașini, prelucrarea metalului, precum și producția de accesorii pentru utilaje de construcții',
    au4: 'Ne bazăm pe forța noastră de inovare și dezvoltare, pe performanța angajaților noștri motivați și pe cunoștințele experților noștri angajați și recunoscuți pentru a dezvolta produsele și tehnologiile noastre, contribuind astfel la creșterea clienților noștri și la succesul comun.',
    at: 'Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent.',
    h10: 'producția de accesorii pentru poliol',
    wfd1: 'Curajul nostru',
    rmb1: 'Mai multe informații',
    rmb2: 'Sunt interesat',
    wfd2: 'Producție de serie și personalizată',
    wfd3: 'Produse din catalog disponibile imediat din depozit. Soluții personalizate pentru cerințe unice ale clienților, cu un timp de execuție scurt.',
    wfd4: 'Asistență pentru servicii Poliol ™',
    wfd5: 'Disponibilitate continuă, livrare în 24-48 ore. Asigurarea unei cupe de schimb sau accesorii pe durata reparației.',
    wfd6: 'Precizie în detalii',
    wfd7: 'Sisteme de cilindri și schimbătoare rapide dezvoltate și produse intern.',
    wfd8: 'Detalii',
    h11: 'producția de mașini de suflat flacoane pentru poliol',
    bmwfd1: 'Producție de mașini',
    bmwfd2: 'inovație centrată pe client',
    bmwfd3: 'POLIOL flacon suflant ale soluțiilor moderne excelente, la prețuri competitive. Experiența și cunoștințele în peste 20 de ani concentrându-se pe proiecte practice și economice pentru client.',
    ml1: 'Locațiile noastre',
    ml2: 'La fața locului, în 8 țări din lume funcționează mașini de suflat flacoane produse de POLIOL, cu satisfacție maximă a clienților.',
    ml3: 'România',
    ml4: 'Algeria',
    ml5: 'Ungaria',
    ml6: 'Belgia',
    ml7: 'Croația',
    ml8: 'Macedonia de Nord',
    ml9: 'Moldova',
    ml10: 'Serbia',
    pmp1: 'Cel mai mare producător de mașini de suflat flacoane din Ungaria',
    pmp2: 'Funcționare fiabilă',
    pmp3: 'Cu utilizarea componentelor electronice și pneumatice de cea mai înaltă calitate',
    pmp4: 'Încărcător hidraulic de preforme',
    pmp5: 'pentru sprijinirea automatizării complete',
    pmp6: 'Cadru puternic',
    pmp7: 'Stabilitatea ridicată a utilajelor noastre începe cu construcția cadrelor solide',
    pmp8: 'Costuri favorabile de operare',
    pmp9: 'Datorită cerințelor scăzute de întreținere și soluțiilor eficiente din punct de vedere energetic',
    h12: 'Ofertă specială! Obțineți acum utilajele noastre din stoc!',
    h13: 'De ce să alegeți acum?',
    h14: 'Disponibil imediat: Fără timp de așteptare, puteți folosi noua dvs. mașină în scurt timp.',
    h15: 'Fiabilitate și calitate: Utilajele noastre și-au demonstrat excelența de-a lungul anilor, indiferent de industrie.',
    h16: 'Prețuri favorabile: Le oferim la un preț special, astfel încât să puteți beneficia de cea mai bună tehnologie în condiții favorabile.',
    h17: 'Nu ratați această oportunitate! Utilajele noastre sunt încă disponibile din stoc.',
    h18: 'producția de flacoane pentru poliol',
    bpm0: 'Producție de flacoane',
    bpm1: '10 miliarde de flacoane experiență',
    bpm2: 'experiență',
    bpm3: 'De la concept la flacon final',
    bpm4: 'Proiectare design personalizat - modelare 3D - producție - etichetare, serigrafie în casă.',
    bpm5: 'Detalii',
    bpm6: 'Alegeți online',
    bpm7: 'Produse din webshop disponibile imediat. Soluții personalizate pentru cerințe unice ale clienților, cu un timp de execuție scurt.',
    bpm8: 'Flacon Webshop',
    bpm9: 'în toate dimensiunile',
    bpm10: 'Proiectăm și producem flacoane de diferite dimensiuni, forme - de la flacoane de 40 ml volum până la flacoane de 12 l volum, cilindrice sau pătrate, dar chiar și cu forme unice',
    bpm11: 'Detalii',
    cup1: 'contact',
    cup2: 'Contactați-ne!',
    cup3: 'Nu ezitați să ne contactați',
    cup4: 'mesaj',
    cup5: 'scrieți-ne:',
    cup6: 'Nume*',
    cup7: 'Telefon*',
    cup8: 'Mesaj (opțional)',
    cup9: 'Trimiteți mesajul',
    cup10: 'Nu ați introdus un nume',
    cup11: 'Nu ai furnizat un număr de telefon',
    cup13: 'Politica de confidențialitate',
    cup14: 'Sunt la curent cu politica de confidențialitate',
    cup15: 'Trebuie să acceptați politica de confidențialitate',
    fc1: 'Vă ajutăm să faceți un pas înainte',
    fc2: 'Producție accesorii și cupe',
    fc3: 'Tősi János',
    fc4: 'Producție de mașini de suflat flacoane',
    fc5: 'Lukács Csaba',
    fc6: 'Producție de flacoane',
    fc7: 'Bíró Adrienn',
    fc8: 'sediu central',
    fc9: 'Toate drepturile rezervate © Poliol Kft. - 2024',
    fc10: 'adresă',
    fc11: 'informații de gestionare a datelor',
    //#endregion

    //BOTTLE BLOWING SITE
    //#region
    //BOTTLE BLOWING REGULAR
    bb1: 'Industrie în care flacoanele noastre au performat deja',
    //BOTTLE BLOWING BANNER
    bbb1: 'Tehnologie HOTFILL',
    bbb2: 'Mențineți-vă avantajul competitiv cu tehnologia noastră revoluționară HOTFILL. Rezistență maximă la căldură și siguranță. Aflați mai multe despre cele mai moderne metode de fabricare a flacoanelor!',
    bbb3: 'Mențineți-vă avantajul competitiv cu tehnologia noastră revoluționară HOTFILL. Rezistență maximă la căldură și siguranță.',
    bbb4: 'Conștientizare ecologică',
    bbb5: 'Producție durabilă de flacoane PET cu soluții ecologice. Descoperiți cum protejăm planeta cu produsele noastre!',
    bbb6: 'Proiectare personalizată',
    bbb7: 'De la concept la flacon final, întregul proces de fabricare a flacoanelor este realizat în casă. De la conceptele personalizate la producția produselor, echipa noastră gestionează totul într-un singur loc. Transformăm eficient și eficace ideile dumneavoastră în flacoane PET de înaltă calitate.',
    bbb8: 'De la concept la flacon final, întregul proces de fabricare a flacoanelor este realizat în casă. De la conceptele personalizate la producția produselor, echipa noastră gestionează totul într-un singur loc.',
    bbb9: 'Etichetare, serigrafie',
    bbb10: 'Creșteți atracția flacoanelor PET cu serviciile noastre de etichetare și serigrafie! Oferim opțiuni de înaltă calitate, personalizate pentru a ajuta produsele dvs. să iasă în evidență pe rafturi!',
    bbb11: 'Creșteți atracția flacoanelor PET cu serviciile noastre de etichetare și serigrafie!',
    //BOTLLE BLOWING PRODUCT CATEGORIES
    bbpc1: 'Industria chimică',
    bbpc2: 'Flacoane PET durabile și sigure pentru stocarea de substanțe chimice și produse de curățenie.',
    bbpc3: 'Industria alimentară',
    bbpc4: 'Flacoane PET igienice și sigure, care păstrează prospețimea și calitatea alimentelor.',
    bbpc5: 'Uleiuri minerale, lubrifianți',
    bbpc6: 'Flacoane PET rezistente, care stochează și transportă în siguranță uleiuri minerale și lubrifianți.',
    bbpc7: 'Cosmetică',
    bbpc8: 'Flacoane PET elegante și durabile, care păstrează prospețimea și calitatea produselor cosmetice.',
    //BOTTLE BLOWING TITLE TEXT IMAGE
    bbtti1: 'de ce flacoane POLIOL',
    bbtti2: 'DUMNEAVOASTRĂ IMAGINAȚI, NOI REALIZĂM',
    bbtti3: 'Flacoanele noastre din materiale plastice de înaltă calitate, proiectate și dezvoltate în mod unic, au performat constant în ultimii ani.',
    bbtti4: 'Proiectăm și producem flacoane de diferite dimensiuni, forme – de la flacoane de 40 ml volum până la flacoane de 12 l volum, cilindrice sau pătrate, dar chiar și cu forme unice – în funcție de nevoile fiecărei mici și mijlocii întreprinderi. Unul dintre cele mai mari succese ale noastre este flaconul de 5 litri de apă minerală Szentkirályi, pe care l-am proiectat pe baza experienței și cunoștințelor noastre și care a primit un Premiu de Excelență.',
    //BOTTLE BLOWING PRODUCT HEROES
    bbpc11: 'HOTFILL',
    bbpc12: 'Prospețime în fiecare picătură',
    bbpc13: 'Bucurați-vă de avantajele noilor noastre produse Hotfill, care permit umplerea lichidelor la temperaturi ridicate pentru a păstra prospețimea gusturilor și pentru o durabilitate îndelungată. Experimentați cum ridicăm ambalarea PET la un nou nivel. Fiți parte din revoluția Hotfill!',
    bbpc14: ['Suc de fructe', 'Ceai', 'Energie', 'Miere', 'Smoothie'],
    bbpc21: 'Alimente',
    bbpc22: 'În slujba depozitării igienice de 30 de ani',
    bbpc23: 'Flacoane PET igienice și sigure, care păstrează prospețimea și calitatea alimentelor. Descoperă soluțiile noastre pentru depozitare sigură!',
    bbpc24: ['Apă', 'Ulei alimentar', 'Lapte', 'Miere', 'Sirop'],
    bbpc31: 'Industira băuturilor alcoolice',
    bbpc32: 'Calitate și în sticlă',
    bbpc33: 'Flacoane PET de înaltă calitate pentru băuturi alcoolice, care păstrează aromele și prospețimea. Alegeți cea mai bună soluție pentru a servi clienții dvs. în calitate premium!',
    bbpc34: ['Bere', 'Vin', 'Palincă', 'Cidru'],
    bbpc41: 'Cosmetică',
    bbpc42: 'Eleganță și durabilitate într-un flacon',
    bbpc43: 'Flacoane PET elegante și durabile, care păstrează prospețimea și calitatea produselor cosmetice. Faceți-vă marca mai atrăgătoare cu soluțiile noastre de ambalare de înaltă calitate!',
    bbpc44: ['Creme', 'Lapte de corp', 'Parfumuri', 'Șampoane'],
    bbpc51: 'Uleiuri minerale, lubrifianți',
    bbpc52: 'Stocare și transport sigur',
    bbpc53: 'Flacoane PET rezistente, care stochează și transportă în siguranță uleiuri minerale și lubrifianți. Răspundem la cerințele stricte ale industriei, asigurând păstrarea produselor în stare perfectă în orice condiții!',
    bbpc54: ['Ulei de motor', 'Lichid de frână', 'Lubrifianți', 'Lichid de răcire'],
    bbpc61: 'Chimicale, produse de curățat',
    bbpc62: 'Siguranță și fiabilitate',
    bbpc63: 'Flacoane PET durabile și sigure pentru stocarea de substanțe chimice și produse de curățenie. Alegeți durabilitatea și calitatea cu produsele noastre!',
    bbpc64: ['Produse de curățat', 'Dezinfectanți', 'Detergenți', 'Chimicale industriale'],
    //#endregion

    //BUCKET PRODUCTION SITE
    //#region
    //BUCKET PRODUCTION REGULAR
    bp1: 'Accesorii noastre',
    //BUCKET PRODUCTION BANNER
    bpb1: 'Cuspic de minerit cu capacitate mare',
    bpb2: 'Soluții inovatoare, cupe de minerit cu rezistență și durabilitate excepționale!',
    bpb3: 'Cupă de înălțime mare de 7 m³',
    bpb4: 'Proiectăm și producem soluții unice, întotdeauna în funcție de cerințele specifice ale clienților!',
    bpb5: 'Fișă de produs',
    //BUCKET PRODUCTION CATEGORIES
    bpc1: 'Industria lemnului, Agricultură',
    bpc2: 'Cupa și accesorii durabile și eficiente pentru industria lemnului și agricultură. 30 de ani de experiență, proiectare și producție performante!',
    bpc3: 'Lucrări grele de teren',
    bpc4: 'Cupe și accesorii rezistente pentru lucrări grele de teren. Creșteți eficiența și minimizați timpul de nefuncționare!',
    bpc5: 'Construcții de utilități',
    bpc6: 'Instrumente fiabile pentru construcții de utilități. Comandați astăzi și beneficiați de serviciul nostru rapid de livrare!',
    bpc7: 'Întreținerea orașelor',
    bpc8: 'Cupe și accesorii profesionale pentru întreținerea eficientă a orașelor. Proiectăm, producem și oferim fiabilitate!',
    bpc9: 'Minerit',
    bpc10: 'Instrumente fabricate din material Hardox durabile, pentru nevoile minelor de piatră și rocă. Descoperiți produsele noastre, care rezistă și în cele mai dure condiții!',
    bpc11: 'Schimbători rapidi de accesorii',
    bpc12: 'Schimbători rapidi și fiabili pentru eficiență maximă.',
  //BUCKET PRODUCTION TITLE TEXT IMAGE
    bptti1: 'de ce POLIOL accesorii pentru cupe',
    bptti2: 'Aveți curajul cu noi!',
    bptti3: 'Poliol are peste 30 de ani de experiență în producția de cupe și accesorii. Compania noastră oferă soluții inovatoare care garantează durabilitatea și fiabilitatea în toate industriile.',
    bptti4: 'Oferim cilindri de lucru și sisteme de cuplare rapidă dezvoltate și fabricate intern. Aceste inovații asigură fiabilitate și eficiență pentru o gamă largă de aplicații. Echipa noastră dispune de unelte profesionale și cunoștințe pentru recondiționarea și modernizarea cupe și accesoriilor, garantând durabilitatea și eficiența echipamentelor în diverse industrii. Produsele noastre sunt fabricate din materiale HARDOX, cunoscute pentru rezistența și durabilitatea lor excepționale, asigurând performanțe pe termen lung. Dar nu doar fiabilitatea ne caracterizează, ci și rapiditatea: livrăm produsele din catalog în cel mult 2 săptămâni!',

    //BOTTLE BLOWING MACHINE PRODUCTION
  //#region
  //BOTTLE BLOWING MACHINE PRODUCTION REGULAR
    bbmp1: 'Tipurile noastre de mașini de suflat flacoane',
    bbmp2: 'Mașinile noastre de suflat flacoane',
    bbmp3: 'Mașini adiționale',
    bbmp4: 'Ascunde produse',
    bbmProdProperties: ['Număr de cavități', 'Volum maxim', 'Capacitate maximă de producție'],

  //BOTTLE BLOWING MACHINE PRODUCTION BANNER
    bbmpb1: 'PA-2 ECO+',
    bbmpb2: 'Cel mai eficient instrument pentru înlocuirea mașinilor semi-automate.',
    bbmpb3: 'PE-UNI',
    bbmpb4: '30 de ani de dezvoltare și fiabilitate într-o nouă haină.',

  //BOTTLE BLOWING MACHINE PRODUCTION CATEGORIES
    bbmpc1: 'Fabrici de lactate',
    bbmpc2: 'Cu mașinile noastre, puteți produce flacoane PET de înaltă calitate care asigură păstrarea în siguranță și pe termen lung a produselor lactate.',
    bbmpc3: 'Berării, crame',
    bbmpc4: 'Inovație peste tot: produceți flacoane inovatoare, moderne și elegante in-house! Calitate și durabilitate a produselor!',
    bbmpc5: 'Producția de băuturi răcoritoare',
    bbmpc6: 'Ca producător de băuturi răcoritoare, puteți produce flacoane PET durabile și estetice. Asigurați atractivitatea și siguranța produselor dumneavoastră in-house cu mașinile noastre!',
    bbmpc7: 'Companii de cosmetice',
    bbmpc8: 'Îmbunătățiți atractivitatea mărcii dumneavoastră cu flacoane PET durabile și elegante care asigură siguranța și prospețimea produselor. Produceți flacoane PET in-house cu mașinile noastre!',
    bbmpc9: 'Fabrici de uleiuri',
    bbmpc10: 'Flacoane rezistente, de calitate, care respectă cele mai stricte cerințe industriale. Asigurați integritatea produselor in-house!',

  //BOTTLE BLOWING MACHINE PRODUCTION TITLE TEXT IMAGE
    bbmptti1: 'de ce să alegeți Poliol?',
    bbmptti2: 'Cele mai personalizabile mașini de suflat flacoane din lume',
    bbmptti3: 'Mașinile de suflat flacoane Poliol reprezintă vârful personalizării și fiabilității. Cu peste 30 de ani de experiență în proiectare și producție, asigurăm că mașinile noastre respectă cele mai stricte cerințe industriale.',
    bbmptti4: 'Prin soluțiile noastre inovatoare, mașinile noastre sunt nu doar eficiente energetic și economisesc spațiu, ci oferă și opțiuni rapide de schimbare, maximizând eficiența operațională. Sistemul de operare cu motor servo și sistemul hidraulic de încărcare a materialului preformat asigură o producție precisă și fiabilă. De peste trei decenii, ne specializăm în mașini liniare de suflare a flacoanelor, oferind expertiză și fiabilitate de neegalat pe piață. Mașinile noastre se caracterizează printr-o construcție stabilă și robustă, eficiență energetică și un design economic de spațiu, asigurând integrarea fără probleme în mediul dumneavoastră de producție. Alegeți mașinile de suflat flacoane Poliol și bucurați-vă de soluții complet personalizabile și de înaltă calitate!',

  //BOTTLE BLOWING MACHINE PRODUCTION PRODUCT HEROES
    bbmppc11: '30 de ani de calitate într-o haină nouă.',
    bbmppc12: 'Familia de mașini UNI, mai versatilă în utilizare, se evidențiează prin funcționarea complet automată. Garantează schimbări rapide, o presiune de suflare mai mare și flacoane de volum mai mare pentru clienții noștri.',
    bbmppc13: 'POLIOL Finance™ Opțiuni de leasing: Opțiuni de leasing flexibile pentru a satisface nevoile dumneavoastră financiare.',
    bbmppc14: ['Volume producibile între 0.2L - 19L', 'schimbare fără unelte în 10 minute', 'capacitate HOTFILL', 'Până la 40 Bar presiune de suflare pentru cele mai mari provocări'],
    bbmppc21: 'Cel mai eficient instrument pentru înlocuirea mașinilor semi-automate.',
    bbmppc22: 'În proiectarea mașinilor noastre de suflat de serie economia, eficiența energetică și durabilitatea au fost priorități principale, menținând în același timp nivelul maxim de calitate și funcționare fiabilă caracteristice producției noastre.',
    bbmppc23: 'POLIOL Finance™ Opțiuni de leasing: Opțiuni de leasing flexibile pentru a satisface nevoile dumneavoastră financiare.',
    bbmppc24: ['Funcționare complet automată', 'Consum redus de energie', 'Sistem de recuperare a aerului', 'Design compact', 'Instalare rapidă și ușoară'],
    bph: ' buc/ora',

  //PRODUCT PAGE
  //#region
    pp1: 'Caracteristici',
    pp2: 'Solicitați o ofertă',
    pp3: 'Utilizare recomandată',
    pp4: 'Galerie',
  //#endregion
  //PRODUCT GROUPS
  //#region
    prodGroupLocale:
        [
          {
            id: 'buckets',
            name: 'Accesorii',
            types: [
              {
                id: 'faipar',
                name: 'Industria Lemnului, Agricultură',
                subtitle: 'Cupe și accesorii durabile și eficiente pentru industria lemnului și agricultură. 30 de ani de experiență, linie de proiectare și producție de înaltă performanță!',
              },
              {
                id: 'foldmunka',
                name: 'Lucrări pe scară largă de terasament',
                subtitle: 'Cupe și accesorii rezistente pentru lucrări pe scară largă de terasament. Creșteți eficiența și minimizați timpul de nefuncționare!',
              },
              {
                id: 'kozmu',
                name: 'Construcții de utilități',
                subtitle: 'Unelte de încredere pentru construcții de utilități. Comandați astăzi și profitați de serviciul nostru rapid de livrare!',
              },
              {
                id: 'varos',
                name: 'Întreținerea orașelor',
                subtitle: 'Cupe și accesorii profesionale pentru întreținerea eficientă a orașelor. Proiectăm, producem și oferim fiabilitate!',
              },
              {
                id: 'banyaszat',
                name: 'Minerit',
                subtitle: 'Unelte fabricate din material durabil Hardox pentru nevoile minelor de piatră și rocă. Descoperiți produsele noastre care rezistă chiar și în cele mai dure condiții!',
              },
              {
                id: 'gyorscserelok',
                name: 'Cuplaje rapide',
                subtitle: 'Cuplaje rapide și de încredere pentru eficiență maximă. Alegeți din soluțiile noastre inovatoare sau proiectăm împreună ceva complet unic!',
              },
            ]
          },
          {
            id: 'bottles',
            name: 'Flacoane',
            types: [
              {
                id: 'vegyipar',
                image: 'vegyipar.webp',
                name: 'Industria Chimică',
                subtitle: 'Flacoane PET durabile și sigure pentru stocarea substanțelor chimice și a produselor de curățare.',
              },
              {
                id: 'elelmiszeripar',
                image: 'Uditogyartas.webp',
                name: 'Industria Alimentară',
                subtitle: 'Flacoane PET igienice și sigure care păstrează prospețimea și calitatea alimentelor.',
              },
              {
                id: 'asvanyi-olaj-kenoanyag',
                image: 'Olajuzemek.webp',
                name: 'Uleiuri Minerale, Lubrifianți',
                subtitle: 'Flacoane PET rezistente care stochează și transportă în siguranță uleiuri minerale și lubrifianți.',
              },
              {
                id: 'kozmetikai',
                image: 'Kozmetikai-cegek.webp',
                name: 'Cosmetice',
                subtitle: 'Flacoane PET elegante și durabile care păstrează prospețimea și calitatea produselor cosmetice.',
              },
            ]
          },
        ],

    prodLocale: [
      {
        id: 'magasuritesu-kanal',
        name: 'Cupă de descărcare înaltă',
        properties: [
          'Corp robust de cupă',
          'Lamă de tăiere HARDOX 500',
          'Design unic anti-scurgere',
          'Calcul real al volumului'
        ],
        usage: 'Recomandată pentru sarcinile de descărcare la înălțime.',
        text: 'Cupa de descărcare înaltă este un echipament proiectat pentru cele mai mari sarcini industriale, permițând mutarea rapidă și eficientă a materialelor în cantități mari. Construcția robustă și structura extra întărită asigură performanța maximă chiar și în condițiile de încărcare extremă. Fabricată din materiale de înaltă calitate, cupa garantează o rezistență excepțională la uzură, asigurând o durată lungă de viață pentru utilizarea continuă.\nNu lăsați sarcinile mari să vă oprească - alegeți cupa de descărcare înaltă pentru eficiență și fiabilitate! Conduceți performanța pe șantier cu un echipament care asigură cea mai mare productivitate și succes în orice condiții!'
      },
      {
        id: 'raklapvilla',
        name: 'Furcă pentru paleți',
        properties: [
          'Gamă largă de capacitate de încărcare',
          'Fabricată pentru toate tipurile de utilaje, nu doar pentru încărcătoare',
          'Certificat CE',
        ],
        usage: 'Recomandată în primul rând pentru manipularea mărfurilor paletizate, precum și pentru orice alt obiect care se încadrează în capacitatea de încărcare.',
        text: 'Furca pentru paleți este ideală pentru transportul sigur și eficient al paleților și diferitelor încărcături. Echipamentul este de înaltă calitate, fiabil și durabil. Personalizată în funcție de nevoile individuale, furca pentru paleți facilitează executarea sarcinilor de manipulare mai ușor și mai precis. Alegeți furca pentru paleți pentru calitate superioară și eficiență!'
      },
      {
        id: 'konnyuanyag-kanal',
        name: 'Cupă pentru materiale ușoare',
        properties: [
          'Profil de cupă anti-scurgere, lamă de tăiere HARDOX 500, capacitate mare fără greutate suplimentară, opțiune cu lamă de tăiere fixată cu șuruburi',
          'Profil de cupă anti-scurgere',
          'Lamă de tăiere HARDOX 500',
          'Capacitate mare fără greutate suplimentară',
          'Opțiune cu lamă de tăiere fixată cu șuruburi'
        ],
        usage: 'Recomandată pentru încărcarea materialelor în vrac utilizate în agricultură și furaje granulare, atât în versiuni fixe cât și de descărcare înălțată.',
        text: 'Cupa pentru materiale ușoare este ideală pentru încărcarea și transportul materialelor ușoare, cum ar fi așchii de lemn, compost și deșeuri. Echipamentul este de înaltă calitate și extrem de rezistent la uzură, asigurând o durată lungă de viață. Este o alegere perfectă pentru lucrări eficiente și precise. Alegeți cupa pentru materiale ușoare pentru sarcinile de manipulare a materialelor ușoare!'
      },
      {
        id: 'rezsu-kanal',
        name: 'Cupă de profil',
        properties: [
          'Lamă de tăiere HARDOX 500',
          'Profil de cupă robust, unic',
          'Disponibil în mai multe profile',
        ],
        usage: 'Recomandată pentru săpături de profile, curățarea albiilor și șanțurilor.',
        text: 'Cupă de profil fixă este soluția perfectă pentru lucrările de terasament, în special pentru săparea șanțurilor, pantele și profilurilor de teren. Echipamentul este de înaltă calitate și extrem de rezistent la uzură, asigurând o durată lungă de viață. Permite o muncă mai precisă și eficientă. Alegeți cupa de profil fixă pentru lucrări de terasament precise și de încredere!',
      },
      {
        id: 'hidraulikus-rezsu-kanal',
        name: 'Cupă de profil hidraulic',
        properties: [
          'Lamă de tăiere și margine HARDOX 500',
          'Corp robust de cupă din oțel S355',
          'Mai multe fiare de întărire',
          'Profil modern de cupă',
          'Suprafață de netezire extensibilă fără costuri suplimentare',
          'Lucru precis cu debit ajustabil pentru protecția cilindrilor de concurență și pentru muncă precisă'
        ],
        usage: 'Recomandată pentru toate sarcinile de săparea profilului și atunci când eliminarea denivelărilor terenului nu este posibilă pentru mașină.',
        text: 'Cupă de profil hidraulic revoluționează formarea profilurilor și șanțurilor, permițând lucrări precise și eficiente pe orice șantier. Sistemul inovator hidraulic permite manevrarea ușoară și precisă, precum și lucrările de terasament de înaltă performanță pentru operator. Cupa fabricată din materiale de înaltă calitate asigură o rezistență excepțională la uzură și o durată lungă de viață pentru o utilizare îndelungată și de încredere.\nAlegeți modelul hidraulic pentru aplicabilitatea largă și flexibilitatea lucrărilor eficiente de terasament! Încredințați cele mai precise procese de lucru unui echipament care reprezintă vârful performanței și fiabilității pe orice șantier!'
      },
      {
        id: 'melyaso-kanal-korom-nelkul',
        name: 'Cupă de săpat adânc cu lamă de tăiere',
        properties: [
          'Profil de cupă proiectat pe baza feedback-ului operatorilor',
          'Lamă și margine HARDOX 500',
          'Disponibil în versiuni standard, întărită și extra întărită',
          'Pentru toate tipurile de excavatoare'
        ],
        usage: 'Recomandată pentru cele mai multe săpături sub nivelul solului și încărcare.',
        text: 'Cupă de săpat adânc este ideală pentru săpături adânci, cum ar fi fundațiile și șanțurile. Echipamentul fabricat conform cerințelor individuale este de înaltă calitate, fiabil și durabil. Este o alegere excelentă pentru lucrări de terasament precise și eficiente. Alegeți cupa de săpat adânc pentru lucrări profesionale și rezultate excelente!'
      },
      {
        id: 'rezsu-kanal-varos',
        name: 'Cupă de profil',
        properties: [
          'Lamă de tăiere HARDOX 500',
          'Profil de cupă robust, unic',
          'Disponibil în mai multe profile',
        ],
        usage: 'Recomandată pentru săpături de profile, curățarea albiilor și șanțurilor.',
        text: 'Cupă de profil fixă este soluția perfectă pentru lucrările de terasament, în special pentru săparea șanțurilor, pantele și profilurilor de teren. Echipamentul este de înaltă calitate și extrem de rezistent la uzură, asigurând o durată lungă de viață. Permite o muncă mai precisă și eficientă. Alegeți cupa de profil fixă pentru lucrări de terasament precise și de încredere!',
      },
      {
        id: 'melyaso-kanal',
        name: 'Cupă de săpat adânc cu gheară',
        properties: [
          'Profil de cupă proiectat pe baza feedback-ului operatorilor',
          'Lamă și margine HARDOX 500',
          'Sistem de gheare opțional',
          'Echipată cu benzi de uzură pe placa de burtă',
          'Disponibil în versiuni standard, întărită și extra întărită',
          'Pentru toate tipurile de excavatoare'
        ],
        usage: 'Recomandată pentru cele mai multe săpături sub nivelul solului și încărcare.',
        text: 'Cupă de săpat adânc este ideală pentru săpături adânci, cum ar fi fundațiile și șanțurile. Echipamentul fabricat conform cerințelor individuale este de înaltă calitate, fiabil și durabil. Este o alegere excelentă pentru lucrări de terasament precise și eficiente. Alegeți cupa de săpat adânc pentru lucrări profesionale și rezultate excelente!'
      },
      {
        id: 'trapez-kanal-foldmunka',
        name: 'Cupă trapezoidală',
        properties: [
          'Lamă HB 500',
          'Disponibilă cu margini înclinate și ajustabile hidraulic',
          'Profil proiectat pe baza feedback-ului operatorilor',
        ],
        usage: 'Recomandată pentru săparea șanțurilor - profiluri simetrice și asimetrice care nu necesită lucrări ulterioare.',
        text: 'Descoperiți versatilitatea cupei trapezoidale, care oferă soluții ideale pentru săparea, formarea și întreținerea șanțurilor, precum și pentru întreținerea canalelor! Fabricată din materiale de înaltă calitate și foarte rezistentă la uzură, asigurând o durată lungă de viață și fiabilitate pe parcursul lucrărilor. Îmbunătățiți nivelul lucrărilor dumneavoastră folosind cupa trapezoidală, care permite obținerea rezultatelor precise și eficiente în orice condiții. Cea mai bună alegere atunci când contează calitatea și performanța!'
      },
      {
        id: 'melyaso-kanal-korom-nelkul-kozmu',
        name: 'Cupă de săpat adânc cu lamă de tăiere',
        properties: [
          'Profil de cupă proiectat pe baza feedback-ului operatorilor',
          'Lamă și margine HARDOX 500',
          'Disponibil în versiuni standard, întărită și extra întărită',
          'Pentru toate tipurile de excavatoare'
        ],
        usage: 'Recomandată pentru cele mai multe săpături sub nivelul solului și încărcare.',
        text: 'Cupă de săpat adânc este ideală pentru săpături adânci, cum ar fi fundațiile și șanțurile. Echipamentul fabricat conform cerințelor individuale este de înaltă calitate, fiabil și durabil. Este o alegere excelentă pentru lucrări de terasament precise și eficiente. Alegeți cupa de săpat adânc pentru lucrări profesionale și rezultate excelente!'
      },
      {
        id: 'trapez-kanal-kozmuepites',
        name: 'Cupă trapezoidală',
        properties: [
          'Lamă HB 500',
          'Disponibilă cu margini înclinate și ajustabile hidraulic',
          'Profil proiectat pe baza feedback-ului operatorilor',
        ],
        usage: 'Recomandată pentru săparea șanțurilor - profiluri simetrice și asimetrice care nu necesită lucrări ulterioare.',
        text: 'Descoperiți versatilitatea cupei trapezoidale, care oferă soluții ideale pentru săparea, formarea și întreținerea șanțurilor, precum și pentru întreținerea canalelor! Fabricată din materiale de înaltă calitate și foarte rezistentă la uzură, asigurând o durată lungă de viață și fiabilitate pe parcursul lucrărilor. Îmbunătățiți nivelul lucrărilor dumneavoastră folosind cupa trapezoidală, care permite obținerea rezultatelor precise și eficiente în orice condiții. Cea mai bună alegere atunci când contează calitatea și performanța!'
      },
      {
        id: 'ripper',
        name: 'Rupător',
        properties: [
          'Corp principal din oțel structural S355',
          'Înveliș HARDOX 500',
          'Sistem de gheară ușor de schimbat',
          'Proiectare care se tocește constant ascuțit',
          'Compatibil cu orice tip de prindere'
        ],
        usage: 'Recomandat pentru afânarea solurilor dure, săpături de pâlcuri și pentru scoaterea materialelor încastrate în sol.',
        text: 'Descoperiți versatilitatea ruptorului, un instrument esențial nu doar pentru mini mașini, ci și pentru cele mai mari excavatoare. Acest echipament inovator permite afânarea solurilor dure, astfel încât materialele să poată fi încărcate ușor și eficient ulterior. Folosiți o soluție care garantează eficiență și productivitate pe orice teren de lucru!'
      },
      {
        id: 'hidraulikus-rezsu-kanal-kozmu',
        name: 'Cupă de profil hidraulic',
        properties: [
          'Lamă de tăiere și margine HARDOX 500',
          'Corp robust de cupă din oțel S355',
          'Mai multe fiare de întărire',
          'Profil modern de cupă',
          'Suprafață de netezire extensibilă fără costuri suplimentare',
          'Lucru precis cu debit ajustabil pentru protecția cilindrilor de concurență și pentru muncă precisă'
        ],
        usage: 'Recomandată pentru toate sarcinile de săparea profilului și atunci când eliminarea denivelărilor terenului nu este posibilă pentru mașină.',
        text: 'Cupă de profil hidraulic revoluționează formarea profilurilor și șanțurilor, permițând lucrări precise și eficiente pe orice șantier. Sistemul inovator hidraulic permite manevrarea ușoară și precisă, precum și lucrările de terasament de înaltă performanță pentru operator. Cupa fabricată din materiale de înaltă calitate asigură o rezistență excepțională la uzură și o durată lungă de viață pentru o utilizare îndelungată și de încredere.\nAlegeți modelul hidraulic pentru aplicabilitatea largă și flexibilitatea lucrărilor eficiente de terasament! Încredințați cele mai precise procese de lucru unui echipament care reprezintă vârful performanței și fiabilității pe orice șantier!'
      },
      {
        id: 'erositett-melyaso-kanal',
        name: 'Cupă de mină',
        properties: [
          'Corp de cupă Hardox HiTuf 500',
          'Suduri realizate cu material de adaos rezistent la uzură',
          'Pentru cele mai dificile sarcini de minerit',
          'Echipament personalizat'
        ],
        usage: 'Recomandată pentru cele mai dificile sarcini de minerit și pentru solicitările mari.',
        text: 'Cupă de mină este proiectată special pentru utilizare în medii de minerit și industrie grea, pentru transportul cantităților mari de materiale. Echipamentul este de înaltă calitate și extrem de rezistent la uzură, asigurând o durată lungă de viață. Performanța sa ajută la realizarea sarcinilor mai grele de transport de materiale. Alegeți cupa de mină pentru transport de materiale fiabil și durabil!'
      },
      {
        id: 'erositett-rakodo-kanal',
        name: 'Cupă de încărcare întărită (HDX)',
        properties: [
          'Proiectată pentru cele mai grele lucrări de încărcare minieră',
          'Disponibilă la dimensiuni personalizate pentru toate tipurile de mașini',
          'Toate componentele cupei pot fi echipate cu elemente și segmente rezistente la uzură',
          'Profil de cupă și formă a lamei personalizabile'
        ],
        usage: 'Proiectată pentru lucrări de încărcare minieră. Echipament personalizat conform cerințelor clientului.',
        text: 'Descoperiți performanța și durabilitatea maximă cu cupa de încărcare întărită, proiectată special pentru cele mai grele provocări miniere și industriale. Construcția robustă și componentele supradimensionate asigură performanțe excepționale chiar și în cele mai extreme condiții de lucru. Părțile expuse uzurii ale cupei sunt protejate cu plăci speciale rezistente la uzură, în timp ce marginea întărită asigură protecție maximă și durabilitate.'
      },
      {
        id: 'rakodo-kanal',
        name: 'Cupă de încărcare',
        properties: [
          'Pentru toate tipurile de încărcătoare',
          'Disponibilă cu corp de cupă HARDOX 500',
          'Sistem de gheare opțional de orice dimensiune',
          'Disponibilă în versiuni deschise și de descărcare înălțată',
          'Fabricată în versiuni standard, întărită și minieră'
        ],
        usage: 'Recomandată în principal pentru sarcini de încărcare de la greutăți mici la mari. Pentru toate tipurile de mașini. Echipament variabil cu lamă sau sistem de gheare, în funcție de materialul încărcat.',
        text: 'Cupă de încărcare este versatilă pentru încărcarea și transportul materialelor precum nisip, pietriș și pământ. Echipamentul personalizat este adaptat exact nevoilor dvs., fiind de înaltă calitate. Fiabilitatea și durabilitatea cresc eficiența muncii. Alegeți cupa de încărcare pentru toate sarcinile de încărcare!'
      },
      {
        id: 'ripper-banyaszat',
        name: 'Rupător',
        properties: [
          'Corp principal din oțel structural S355',
          'Înveliș HARDOX 500',
          'Sistem de gheară ușor de schimbat',
          'Proiectare care se tocește constant ascuțit',
          'Compatibil cu orice tip de prindere'
        ],
        usage: 'Recomandat pentru afânarea solurilor dure, săpături de pâlcuri și pentru scoaterea materialelor încastrate în sol.',
        text: 'Descoperiți versatilitatea ruptorului, un instrument esențial nu doar pentru mini mașini, ci și pentru cele mai mari excavatoare. Acest echipament inovator permite afânarea solurilor dure, astfel încât materialele să poată fi încărcate ușor și eficient ulterior. Folosiți o soluție care garantează eficiență și productivitate pe orice teren de lucru!'
      },
      {
        id: 'hidraulikus-kotro-XCMG-gyorscserelo',
        name: 'Schimbător rapid de excavator hidraulic / mecanic',
        properties: [
          'Un echipament esențial pentru versatilitatea excavatorului. Economie de timp, muncă umană și forță motrice hidraulică și mecanică pentru toate tipurile de utilaje.',
          'Un echipament esențial pentru versatilitatea excavatorului',
          'Economie de timp, muncă umană și forță motrice',
          'Disponibil în variante hidraulice și mecanice',
          'Pentru toate tipurile de utilaje'
        ],
        usage: 'Recomandat pentru excavatoarele utilizate pentru scopuri multiple, pentru schimburi frecvente de echipamente. În lume în continuă dezvoltare, este practic dotarea de bază pentru un excavator.',
        text: 'Schimbătorul rapid de excavator hidraulic permite schimbarea rapidă a sculelor excavatorului, crescând semnificativ versatilitatea mașinii. Echipamentul personalizat este de înaltă calitate și durabil. Cu ajutorul său puteți efectua lucrările de terasament mai rapid și mai eficient. Alegeți schimbătorul rapid de excavator hidraulic pentru muncă flexibilă și rapidă!'
      },
      {
        id: 'hidraulikus-rakodo-VOLVO-gyorscserelo',
        name: 'Schimbător rapid de încărcător hidraulic',
        properties: [
          'Permite utilizarea multiplă a încărcătorului.',
        ],
        usage: 'Recomandat pentru încărcătoarele cu utilizare multiplă și schimburi frecvente de echipamente.',
        text: 'Un echipament de bază pentru excavarea șanțurilor, canalelor, și profilurilor.'
      },
      {
        id: 'balavilla',
        name: 'Furcă pentru baloți',
        properties: [
          'Accesoriu cu profil propriu',
          'Design simplu și costuri eficiente',
          'Tije ușor de înlocuit',
          'Compatibilă cu orice tip de prindere'
        ],
        usage: 'Recomandat pentru sectorul agricol pentru manipularea baloților.',
        text: 'Furca pentru balo'
      },
      {
        id: 'tragyavilla',
        name: 'Furcă pentru gunoi',
        properties: [
          'Corp robust și simplu din oțel structural S355',
          'Echipată cu o linie de furci interschimbabile',
          'Disponibilă în versiune cu crocodil hidraulic',
          'Disponibilă și într-o versiune simplă cu furci inferioare'
        ],
        usage: 'Recomandată pentru lucrări agricole, manipularea gunoiului și încărcarea deșeurilor vegetale fibroase.',
        text: 'Furca pentru gunoi este excelentă pentru gestionarea și încărcarea gunoiului pe terenurile agricole. Produsul fabricat conform cerințelor individuale este de înaltă calitate, fiabil și durabil. Vă permite să îndepliniți sarcinile de manipulare a gunoiului în mod eficient și ușor. Fiți parte a evoluției eficienței mecanizării agricole.'
      },
        {
          id: 'pap1eco',
          name: 'PAP-1 ECO',
          overlayText: 'Familia de mașini PA, cu excepția întinderii operate de un servomotor, constă din mașini operate de cilindri pneumatici. Mașinile PAP sunt concepute special pentru producerea de sticle de mic volum, cu cerințe minime de spațiu și energie. Numai acest tip ECO poate fi comandat într-o configurație semiautomată, cu alimentare manuală a preformei și extragere manuală a sticlei. Este o mașină simplă.'
        },
        {
          id: 'pap1',
          name: 'PAP-1',
          overlayText: 'Familia de mașini PA, cu excepția întinderii operate de un servomotor, constă din mașini operate de cilindri pneumatici. Mașinile PAP sunt concepute special pentru producerea de sticle de mic volum, cu cerințe minime de spațiu și energie. Acest tip este complet automat. Controlul mașinii este capabil să deservească o mașină de umplere în modul online, plasând sticlele terminate pe o bandă transportoare cu o unitate suplimentară.'
        },
        {
          id: 'pap1hotFill',
          name: 'PAP-1 HOT FILL',
          overlayText: 'Mașina PAP-1 este echipată cu un pachet suplimentar care permite producerea de sticle care pot fi umplute la cald. Mașina poate trece cu ușurință între producția de sticle HOTFILL sau convenționale.'
        },
        {
          id: 'pa1eco',
          name: 'PA-1 ECO',
          overlayText: 'Familia de mașini PA, cu excepția întinderii operate de un servomotor, constă din mașini operate de cilindri pneumatici. Acest tip este complet automat. Controlul mașinii este capabil să deservească o mașină de umplere în modul online, plasând sticlele terminate pe o bandă transportoare cu o unitate suplimentară. Este o mașină simplă.'
        },
        {
          id: 'pa1hotFill',
          name: 'PA-1 HOT FILL',
          overlayText: 'Mașina PA-1 ECO este echipată cu un pachet suplimentar care permite producerea de sticle care pot fi umplute la cald. Mașina poate trece cu ușurință între producția de sticle HOTFILL sau convenționale.'
        },
        {
          id: 'pe15uni',
          name: 'PE-15 UNI',
          overlayText: 'Toate mișcările principale ale familiei de mașini PE sunt operate de servomotoare, permițând viteze rapide de mișcare care scurtează timpul de ciclu și cresc productivitatea. Familia de mașini este construită folosind cele mai moderne componente electronice și de transmisie a datelor, permițând setări de mișcare și tehnologie foarte precise, cu feedback controlat. Familia PE se caracterizează prin eficiență energetică ridicată și consum minimizat de aer, datorită diverselor sisteme de recuperare a aerului. Sticlele părăsesc mașina prin transportoare de aer, iar controlul este potrivit pentru operare online cu mașini de umplere. Schimbările rapide de produs și preformă sunt susținute de multe soluții integrate și opționale.'
        },
        {
          id: 'pe110uni',
          name: 'PE-110 UNI',
          overlayText: 'Toate mișcările principale ale familiei de mașini PE sunt operate de servomotoare, permițând viteze rapide de mișcare care scurtează timpul de ciclu și cresc productivitatea. Familia de mașini este construită folosind cele mai moderne componente electronice și de transmisie a datelor, permițând setări de mișcare și tehnologie foarte precise, cu feedback controlat. Familia PE se caracterizează prin eficiență energetică ridicată și consum minimizat de aer, datorită diverselor sisteme de recuperare a aerului. Sticlele părăsesc mașina prin transportoare de aer, iar controlul este potrivit pentru operare online cu mașini de umplere. Mașina este dezvoltată special pentru producția de sticle de volum mai mare.'
        },
        {
          id: 'pap2',
          name: 'PAP-2',
          overlayText: 'Familia de mașini PA, cu excepția întinderii operate de un servomotor, constă din mașini operate de cilindri pneumatici. Mașinile PAP sunt concepute special pentru producerea de sticle de mic volum, cu cerințe minime de spațiu și energie. Acest tip este complet automat. Controlul mașinii este capabil să deservească o mașină de umplere în modul online, plasând sticlele terminate pe o bandă transportoare cu o unitate suplimentară. Mașina are aceeași dimensiune ca PAP-1, dar poate produce două sticle mai mici simultan.'
        },
        {
          id: 'pa2ecoplus',
          name: 'PA-2 ECO+',
          overlayText: 'Familia de mașini PA, cu excepția întinderii operate de un servomotor, constă din mașini operate de cilindri pneumatici. Acest tip este complet automat. Controlul mașinii este capabil să deservească o mașină de umplere în modul online. Sticlele părăsesc mașina prin transportoare de aer, iar controlul este potrivit pentru operare online cu mașini de umplere. Versiunea ECO+ este modelul modernizat și eficient din punct de vedere energetic al mașinilor operate pneumatic.'
        },
        {
          id: 'pe2uni',
          name: 'PE-2 UNI',
          overlayText: 'Toate mișcările principale ale familiei de mașini PE sunt operate de servomotoare, permițând viteze rapide de mișcare care scurtează timpul de ciclu și cresc productivitatea. Familia de mașini este construită folosind cele mai moderne componente electronice și de transmisie a datelor, permițând setări de mișcare și tehnologie foarte precise, cu feedback controlat. Familia PE se caracterizează prin eficiență energetică ridicată și consum minimizat de aer, datorită diverselor sisteme de recuperare a aerului. Sticlele părăsesc mașina prin transportoare de aer, iar controlul este potrivit pentru operare online cu mașini de umplere. Schimbările rapide de produs și preformă sunt susținute de multe soluții integrate și opționale.'
        },
        {
          id: 'pe2uniHotFill',
          name: 'PE-2 UNI HOT FILL',
          overlayText: 'Mașina PE-2 este echipată cu un pachet suplimentar care permite producerea de sticle care pot fi umplute la cald. Mașina poate trece cu ușurință între producția de sticle HOTFILL sau convenționale.'
        },
        {
          id: 'pe25uni',
          name: 'PE-25 UNI',
          overlayText: 'Toate mișcările principale ale familiei de mașini PE sunt operate de servomotoare, permițând viteze rapide de mișcare care scurtează timpul de ciclu și cresc productivitatea. Familia de mașini este construită folosind cele mai moderne componente electronice și de transmisie a datelor, permițând setări de mișcare și tehnologie foarte precise, cu feedback controlat. Familia PE se caracterizează prin eficiență energetică ridicată și consum minimizat de aer, datorită diverselor sisteme de recuperare a aerului. Sticlele părăsesc mașina prin transportoare de aer, iar controlul este potrivit pentru operare online cu mașini de umplere. Schimbările rapide de produs și preformă sunt susținute de multe soluții integrate și opționale.'
        },
        {
          id: 'pa210uni',
          name: 'PA-210 UNI',
          overlayText: 'Toate mișcările principale ale familiei de mașini PE sunt operate de servomotoare, permițând viteze rapide de mișcare care scurtează timpul de ciclu și cresc productivitatea. Familia de mașini este construită folosind cele mai moderne componente electronice și de transmisie a datelor, permițând setări de mișcare și tehnologie foarte precise, cu feedback controlat. Familia PE se caracterizează prin eficiență energetică ridicată și consum minimizat de aer, datorită diverselor sisteme de recuperare a aerului. Sticlele părăsesc mașina prin transportoare de aer, iar controlul este potrivit pentru operare online cu mașini de umplere. Schimbările rapide de produs și preformă sunt susținute de multe soluții integrate și opționale.'
        },
        {
          id: 'pe4uni',
          name: 'PE-4 UNI',
          overlayText: 'Toate mișcările principale ale familiei de mașini PE sunt operate de servomotoare, permițând viteze rapide de mișcare care scurtează timpul de ciclu și cresc productivitatea. Familia de mașini este construită folosind cele mai moderne componente electronice și de transmisie a datelor, permițând setări de mișcare și tehnologie foarte precise, cu feedback controlat. Familia PE se caracterizează prin eficiență energetică ridicată și consum minimizat de aer, datorită diverselor sisteme de recuperare a aerului. Sticlele părăsesc mașina prin transportoare de aer, iar controlul este potrivit pentru operare online cu mașini de umplere. Schimbările rapide de produs și preformă sunt susținute de multe soluții integrate și opționale.'
        },
        {
          id: 'pe6uni',
          name: 'PE-6 UNI',
          overlayText: 'Toate mișcările principale ale familiei de mașini PE sunt operate de servomotoare, permițând viteze rapide de mișcare care scurtează timpul de ciclu și cresc productivitatea. Familia de mașini este construită folosind cele mai moderne componente electronice și de transmisie a datelor, permițând setări de mișcare și tehnologie foarte precise, cu feedback controlat. Familia PE se caracterizează prin eficiență energetică ridicată și consum minimizat de aer, datorită diverselor sisteme de recuperare a aerului. Sticlele părăsesc mașina prin transportoare de aer, iar controlul este potrivit pentru operare online cu mașini de umplere. Schimbările rapide de produs și preformă sunt susținute de multe soluții integrate și opționale.'
        },
        {
          id: 'pe12uni',
          name: 'PE-12 UNI',
          overlayText: 'Toate mișcările principale ale familiei de mașini PE sunt operate de servomotoare, permițând viteze rapide de mișcare care scurtează timpul de ciclu și cresc productivitatea. Familia de mașini este construită folosind cele mai moderne componente electronice și de transmisie a datelor, permițând setări de mișcare și tehnologie foarte precise, cu feedback controlat. Modelul PE-12 este dezvoltat special pentru producerea de sticle de mic volum. Sticlele părăsesc mașina prin transportoare de aer, iar controlul este potrivit pentru operare online cu mașini de umplere.'
        }
      ],      
    //IMG ALTS
    //#region
    imgAlt0:'Poliol Logo',
    imgAlt1:'Markoló üríti rakományát.',
    imgAlt2:'Flakonfúvó gép váz rajz',
    imgAlt3:'Víz fröcsköl egy vizesflakonból',
    imgAlt4:'Világtérkép',
    imgAlt5:'Markoló kanállal',
    imgAlt6:'Hegesztés',
    imgAlt7:'Gyártott bányakanál',
    imgAlt8:'Robothegesztés',
    imgAlt9:'Fehér flakonok sorakoznak',
    imgAlt10:'Alakadó szerszám gyártása',
    imgAlt11:'Flakon tervezése',
    imgAlt12:'Futurisztikus vizes flakon terv koncepció',
    imgAlt13:'',
    imgAlt14:'',
    imgAlt15:'',
    imgAlt16:'',
    imgAlt17:'',
    imgAlt18:'',
    imgAlt19:'',
    imgAlt20:'',
    imgAlt21:'',
    imgAlt22:'',
    //#endregion
    //PRIVACY POLICY, DATA PROTECTION
    //#region
    dp1:'Politica de confidențialitate',
    dp2:'Politica de confidențialitate, Protecția datelor, document GDPR',
    cp1:'Politica de reclamații'
    //#endregion
  }