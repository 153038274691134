export default {
    "re1": "Estoy interesado",
    "ty": "¡Gracias!",
    "sites": [
      {"text": "Inicio", "url": ""},
      {"text": "Fabricación de botellas", "url": "botellas", metaName:'',metaContent:''},
      {"text": "Fabricación de cucharas", "url": "cucharas", metaName:'',metaContent:''},
      {"text": "Fabricación de máquinas de soplado de botellas", "url": "maquinas-de-soplado-de-botellas", metaName:'',metaContent:''},
      {text: 'Accesorios', url: 'accesorios',show: false, metaName:'',metaContent:''},
      {text: 'Protección de datos', url: 'datos',right: true, metaName:'',metaContent:''},
      {text: 'Quejas', url: 'quejas',right: true, metaName:'',metaContent:''}
    ],
    "h1": "Treinta años,",
    "h2": "la experiencia ",
    "h3": "es la garantía",
    "h4": "Nuestras soluciones tecnológicas modernas sirven para la comodidad del cliente, la productividad y el aumento de la eficiencia de costos durante muchos años a precios competitivos.",
    "h5": "Máquinas instaladas",
    "h6": "Años de experiencia",
    "h7": "Categoría de productos",
    "h8": "Empleados",
    "hb1": "Fabricación de máquinas de soplado de botellas",
    "hb2": "El mayor fabricante de máquinas de soplado de botellas de Hungría",
    "hb3": "Fabricación de botellas",
    "hb4": "El experto en botellas PET de diseño personalizado",
    "hb5": "Fabricación de utensilios",
    "hb6": "Las cucharas profesionales se fabrican aquí",
    "hb7": "Leer más →",
    "h9": "¡Solicite una oferta!",
    "au1": "Sobre nosotros",
    "au2": "Soluciones interindustriales",
    "au3": "Amplia experiencia profesional en la industria del embalaje de plásticos, fabricación de maquinaria, mecanizado de metales y fabricación de accesorios para maquinaria de movimiento de tierras.",
    "au4": "Desarrollamos nuestros productos y tecnología basándonos en nuestra fuerza de desarrollo e innovación, el rendimiento de nuestros empleados motivados y los conocimientos de nuestros expertos comprometidos y reconocidos, para contribuir al desarrollo de nuestros clientes y nuestro éxito conjunto.",
    "at": "Confiable. Sostenible. Duradero.",
    "h10": "Fabricación de utensilios de polio",
    "wfd1": "Atrévete con nosotros",
    "rmb1": "Más información",
    "rmb2": "Estoy interesado",
    "wfd2": "Producción en serie y personalizada",
    "wfd3": "Productos de catálogo disponibles de inmediato desde stock. Soluciones personalizadas para requisitos específicos del cliente con tiempos de entrega rápidos.",
    "wfd4": "Asistencia de servicio Poliol™",
    "wfd5": "Disponibilidad continua, despacho en 24-48 horas. Provisión de cucharas de repuesto durante el período de reparación.",
    "wfd6": "Precisión en los detalles",
    "wfd7": "Cilindros y sistemas de cambio rápido de producción propia.",
    "wfd8": "Detalles",
    "h11": "Fabricación de máquinas de soplado de botellas de polio",
    "bmwfd1": "Fabricación de maquinaria",
    "bmwfd2": "Innovación centrada en el cliente",
    "bmwfd3": "Los equipos de soplado de botellas de POLIOL, basados en más de 20 años de conocimiento y experiencia de fabricación puramente húngaros, están diseñados y fabricados para proporcionar un valor duradero y una capacidad de producción estable.",
    "ml1": "Nuestras ubicaciones",
    "ml2": "Equipos de soplado de botellas fabricados en POLIOL operan in situ en 8 países de todo el mundo, con la máxima satisfacción del cliente.",
    "ml3": "Rumanía",
    "ml4": "Argelia",
    "ml5": "Hungría",
    "ml6": "Bélgica",
    "ml7": "Croacia",
    "ml8": "Macedonia del Norte",
    "ml9": "Moldavia",
    "ml10": "Serbia",
    "pmp1": "El mayor fabricante de máquinas de soplado de botellas de Hungría",
    "pmp2": "Operación confiable",
    "pmp3": "Utilizando componentes electrónicos y neumáticos de la más alta calidad",
    "pmp4": "Preformador hidráulico",
    "pmp5": "para apoyar la automatización completa",
    "pmp6": "Marco robusto",
    "pmp7": "La destacada estabilidad de nuestras máquinas comienza con la estructura robusta del marco",
    "pmp8": "Bajo coste operativo",
    "pmp9": "Debido a la baja necesidad de servicio y las soluciones eficientes en energía",
    "h12": "¡Oferta especial! ¡Adquiera nuestras máquinas ahora en stock!",
    "h13": "¿Por qué elegir ahora?",
    "h14": "Disponibilidad inmediata: Sin tiempo de espera, puede usar su nueva máquina en poco tiempo.",
    "h15": "Confiabilidad y calidad: Nuestras máquinas han demostrado su excelencia durante años, independientemente de la industria.",
    "h16": "Precios asequibles: Las ofrecemos a un precio especial, para que también pueda beneficiarse de la mejor tecnología en condiciones favorables.",
    "h17": "¡No se pierda esta oportunidad! Nuestras máquinas aún están disponibles en stock.",
    "h18": "Fabricación de botellas de polio",
    "bpm0": "Fabricación de botellas",
    "bpm1": "10 mil millones de botellas experiencia",
    "bpm2": "experiencia",
    "bpm3": "De la idea a la botella terminada",
    "bpm4": "Diseño personalizado - Modelado 3D - Producción - Etiquetado, serigrafiado in-house.",
    "bpm5": "Detalles",
    "bpm6": "Elija en línea",
    "bpm7": "Productos de la tienda disponibles de inmediato. Soluciones personalizadas para requisitos específicos del cliente con tiempo de entrega rápido.",
    "bpm8": "Tienda de botellas",
    "bpm9": "en todos los tamaños",
    "bpm10": "Diseñamos y fabricamos productos en diferentes tamaños y formas - botellas con capacidad de 40 ml a 12 l, cilíndricas o cuadradas, incluso con formas personalizadas según la demanda.",
    "bpm11": "Detalles",
    "cup1": "contacto",
    "cup2": "¡Contáctenos!",
    "cup3": "No dude en contactarnos",
    "cup4": "mensaje",
    "cup5": "escríbanos:",
    "cup6": "Nombre*",
    "cup7": "Teléfono*",
    "cup8": "Mensaje (opcional)",
    "cup9": "Enviar mensaje",
    "cup10": "Nombre no proporcionado",
    "cup11": "Correo electrónico no proporcionado",
    "cup12": "Número de teléfono no proporcionado",
    cup13: 'política de privacidad',
    cup14: 'Estoy al tanto de la política de privacidad',
    cup15: 'Debes aceptar la política de privacidad',
    "fc1": "Te ayudamos a subir de nivel",
    "fc2": "Fabricación de utensilios y cucharas",
    "fc3": "János Tősi",
    "fc4": "Fabricación de máquinas de soplado de botellas",
    "fc5": "Csaba Lukács",
    "fc6": "Fabricación de botellas",
    "fc7": "Adrienn Bíró",
    "fc8": "centro",
    "fc9": "Todos los derechos reservados © Poliol Kft. - 2024",
    "fc10": "dirección",
    fc11: 'información de gestión de datos',

    "bb1": "Industrias donde ya se desempeñan nuestras botellas",
    "bbb1": "Tecnología HOTFILL",
    "bbb2": "Mantenga su ventaja competitiva con nuestra revolucionaria tecnología HOTFILL. Máxima resistencia al calor y seguridad. ¡Descubra más sobre nuestros métodos de producción de botellas más modernos!",
    "bbb3": "Mantenga su ventaja competitiva con nuestra revolucionaria tecnología HOTFILL. Máxima resistencia al calor y seguridad.",
    "bbb4": "Conciencia ambiental",
    "bbb5": "Producción sostenible de botellas PET con soluciones ecológicas. ¡Descubra cómo protegemos el planeta con nuestros productos!",
    "bbb6": "Diseño personalizado",
    "bbb7": "De la idea a la botella terminada, realizamos todo el proceso de producción de botellas internamente. Desde los conceptos de diseño personalizado hasta la producción, nuestro equipo maneja todo en un solo lugar. Transformamos sus diseños en botellas PET de alta calidad, de manera eficiente y eficaz.",
    "bbb8": "De la idea a la botella terminada, realizamos todo el proceso de producción de botellas internamente. Desde los conceptos de diseño personalizado hasta la producción, nuestro equipo maneja todo en un solo lugar.",
    "bbb9": "Etiquetado, serigrafiado",
    "bbb10": "¡Aumente el atractivo de las botellas PET con nuestros servicios de etiquetado y serigrafiado! Opciones de alta calidad y personalizadas para que sus productos destaquen en las estanterías.",
    "bbb11": "¡Aumente el atractivo de las botellas PET con nuestros servicios de etiquetado y serigrafiado!",
    "bbpc1": "Industria química",
    "bbpc2": "Botellas PET duraderas y seguras para almacenar productos químicos y detergentes.",
    "bbpc3": "Industria alimentaria",
    "bbpc4": "Botellas PET higiénicas y seguras que preservan la frescura y calidad de los alimentos.",
    "bbpc5": "Aceite mineral, lubricante",
    "bbpc6": "Botellas PET resistentes que almacenan y transportan con seguridad aceites minerales y lubricantes.",
    "bbpc7": "Cosméticos",
    "bbpc8": "Botellas PET elegantes y duraderas que preservan la frescura y calidad de los productos cosméticos.",
    "bbtti1": "por qué las botellas de POLIOL",
    "bbtti2": "USTED LO IMAGINA, NOSOTROS LO REALIZAMOS",
    "bbtti3": "Nuestras botellas de material plástico producidas con alta calidad y diseño propio han demostrado sistemáticamente su calidad en los últimos años.",
    "bbtti4": "Diseñamos y fabricamos una variedad de tamaños y formas, desde botellas con capacidad de 40 ml hasta 12 l, cilíndricas o cuadradas, e incluso productos con formas personalizadas según las necesidades específicas de las pequeñas y medianas empresas. Uno de nuestros mayores éxitos es nuestra botella de 5 litros de agua mineral Szentkirályi, diseñada y en uso hoy, que recibió el Premio de Excelencia.",
    "bbpc11": "HOTFILL",
    "bbpc12": "Frescura en cada gota",
    "bbpc13": "Disfrute de las ventajas de nuestros nuevos productos Hotfill, que permiten el llenado de líquidos a alta temperatura para preservar los sabores frescos y prolongar la vida útil. ¡Experimente cómo llevamos la tecnología de envasado PET a un nuevo nivel! ¡Únase a la revolución Hotfill!",
    "bbpc14": ["Zumo", "Té", "Bebida energética", "Miel", "Smoothie"],
    "bbpc21": "Alimentos",
    "bbpc22": "Al servicio del almacenamiento higiénico durante 30 años",
    "bbpc23": "Botellas PET higiénicas y seguras que preservan la frescura y calidad de los alimentos. ¡Descubra nuestras soluciones para el almacenamiento seguro!",
    "bbpc24": ["Agua", "Aceite comestible", "Leche", "Miel", "Jarabe"],
    "bbpc31": "Industria del alcohol",
    "bbpc32": "Calidad en la botella también",
    "bbpc33": "Botellas PET de alta calidad para bebidas alcohólicas, que preservan los sabores y la frescura. ¡Elija la mejor solución para ofrecer a sus clientes una calidad superior!",
    "bbpc34": ["Cerveza", "Vino", "Licor", "Sidra"],
    "bbpc41": "Cosméticos",
    "bbpc42": "Elegancia y durabilidad en una botella",
    "bbpc43": "Botellas PET elegantes y duraderas que preservan la frescura y calidad de los productos cosméticos. ¡Haga que su marca sea atractiva con nuestras excelentes soluciones de embalaje!",
    "bbpc44": ["Cremas", "Lociones", "Geles de ducha", "Champús"],
    "bbpc51": "Aceite mineral, lubricante",
    "bbpc52": "Almacenamiento y transporte seguros",
    "bbpc53": "Botellas PET resistentes que almacenan y transportan con seguridad aceites minerales y lubricantes. Confíe en nosotros para el embalaje, para que sus productos se mantengan en perfecto estado en todas las circunstancias.",
    "bbpc54": ["Aceite de motor", "Líquido de frenos", "Lubricantes", "Refrigerante"],
    "bbpc61": "Productos químicos, detergentes",
    "bbpc62": "Seguridad y fiabilidad",
    "bbpc63": "Botellas PET duraderas y seguras para almacenar productos químicos y detergentes. ¡Elija la fiabilidad y la calidad con nuestros productos!",
    "bbpc64": ["Detergentes", "Desinfectantes", "Detergentes", "Productos químicos industriales"],
    "bp1": "Nuestros utensilios",
    "bpb1": "Cuchara de minería de alta capacidad",
    "bpb2": "¡Soluciones innovadoras, cucharas mineras de resistencia y durabilidad excepcionales!",
    "bpb3": "Cuchara de vaciado alto de 7 m³",
    "bpb4": "Diseñamos y fabricamos soluciones personalizadas, siempre según las necesidades específicas del cliente.",
    "bpb5": "Ficha del producto",
    "bpc1": "Industria de la madera, Agricultura",
    "bpc2": "Cucharas y accesorios duraderos y eficientes para la industria de la madera y la agricultura. 30 años de experiencia, alta capacidad de diseño y fabricación.",
    "bpc3": "Obras de movimiento de tierras de gran volumen",
    "bpc4": "Cucharas y accesorios fuertes para obras de movimiento de tierras de gran volumen. ¡Mejore su eficiencia y minimice el tiempo de inactividad!",
    "bpc5": "Construcción de servicios públicos",
    "bpc6": "Herramientas confiables para la construcción de servicios públicos. ¡Haga su pedido hoy y aproveche nuestro servicio de entrega rápida!",
    "bpc7": "Mantenimiento de la ciudad",
    "bpc8": "Cucharas y accesorios profesionales para un mantenimiento de la ciudad eficiente. ¡Planificamos, fabricamos y ofrecemos confiabilidad!",
    "bpc9": "Minería",
    "bpc10": "Herramientas fabricadas con material Hardox para las necesidades de la minería de piedra y roca. Descubra nuestros productos que soportan incluso las condiciones más duras.",
    "bpc11": "Cambiadores rápidos de accesorios",
    "bpc12": "Cambiadores rápidos y confiables de accesorios para la máxima eficiencia. ¡Elija entre nuestras soluciones innovadoras o diseñemos algo completamente personalizado juntos!",
    "bptti1": "por qué POLIOL utensilios",
    "bptti2": "¡Atrévete con nosotros!",
    "bptti3": "Poliol tiene más de 30 años de experiencia en la fabricación de cucharas y accesorios. Nuestra empresa ofrece soluciones innovadoras que garantizan durabilidad y fiabilidad en todos los sectores industriales.",
    "bptti4": "Ofrecemos cilindros y sistemas de cambio rápido de producción propia. Estas innovaciones garantizan fiabilidad y eficiencia en las aplicaciones más diversas. Nuestro equipo tiene herramientas profesionales y conocimientos en la renovación y modernización de cucharas y accesorios, asegurando la durabilidad y eficiencia de los equipos en los sectores más diversos. Nuestros productos están fabricados con materiales Hardox, conocidos por su excepcional durabilidad, garantizando un rendimiento a largo plazo. Pero no solo somos conocidos por nuestra fiabilidad, sino también por nuestra rapidez: ¡envíamos los productos de nuestro catálogo dentro de 2 semanas!",
    "bbmp1": "Nuestros tipos de máquinas de soplado de botellas",
    "bbmp2": "Nuestras máquinas de soplado de botellas",
    "bbmp3": "Más máquinas",
    "bbmp4": "Ocultar productos",
    "bbmProdProperties": ["Número de cavidades", "Volumen máximo", "Capacidad máxima"],
    "bbmpb1": "PA-2 ECO+",
    "bbmpb2": "La herramienta más eficiente para sustituir las máquinas semiautomáticas.",
    "bbmpb3": "PE-UNI",
    "bbmpb4": "30 años de desarrollo y fiabilidad en una nueva apariencia.",
    "bbmpc1": "Lácteos",
    "bbmpc2": "Con nuestras máquinas, puede producir botellas PET de la más alta calidad, garantizando un almacenamiento seguro y duradero de los productos lácteos.",
    "bbmpc3": "Cervecerías, bodegas",
    "bbmpc4": "Innovación sobre todo: ¡produzca botellas innovadoras, de moda y elegantes internamente! ¡Calidad de producto y durabilidad!",
    "bbmpc5": "Industria de bebidas",
    "bbmpc6": "Puede producir botellas PET duraderas y estéticas como fabricante de bebidas. ¡Asegúrese de que sus productos sean atractivos y seguros con nuestras máquinas!",
    "bbmpc7": "Empresas cosméticas",
    "bbmpc8": "Aumente el atractivo de su marca con botellas PET duraderas y elegantes, que garantizan la seguridad y frescura de los productos. ¡Produzca botellas PET internamente con nuestras máquinas!",
    "bbmpc9": "Fábricas de aceite",
    "bbmpc10": "Botellas de calidad y duraderas que cumplen con los requisitos industriales más exigentes. ¡Asegure la integridad de los productos con nuestras máquinas!",
    "bbmptti1": "por qué Poliol",
    "bbmptti2": "Las máquinas de soplado de botellas más personalizables del mundo",
    "bbmptti3": "Las máquinas de soplado de botellas de Poliol representan la cúspide de la personalización y fiabilidad. Con más de 30 años de experiencia en diseño y fabricación, aseguramos que nuestras máquinas cumplan con los requisitos industriales más exigentes.",
    "bbmptti4": "Nuestras soluciones innovadoras hacen que nuestras máquinas no solo sean eficientes en el uso de energía y espacio, sino que también ofrezcan opciones de cambio rápido, maximizando la eficiencia operativa. El movimiento por servomotor y el sistema de alimentación de preformas hidráulico garantizan una producción precisa y confiable. Durante más de tres décadas, nos hemos especializado en máquinas de soplado de botellas lineales, ofreciendo una experiencia y fiabilidad inigualables en el mercado. Nuestras máquinas se caracterizan por su construcción estable y robusta, eficiencia energética y ahorro de espacio, asegurando una integración perfecta en su entorno de producción. ¡Elija las máquinas de soplado de botellas de Poliol y disfrute de soluciones completamente personalizables y de alta calidad!",
    "bbmppc11": "30 años de calidad en una nueva apariencia.",
    "bbmppc12": "La familia de máquinas UNI, que ofrece un uso más universal, destaca en la lista de productos por su funcionamiento totalmente automático. Garantiza cambios rápidos, mayor presión de soplado y botellas de mayor capacidad para nuestros clientes.",
    "bbmppc13": "POLIOL Finance™ Opciones de alquiler: Opciones de alquiler flexibles según sus necesidades financieras.",
    "bbmppc14": ["Capacidad de volumen de fabricación: 0.2 l - 19 l", "Cambio sin herramientas en 10 minutos", "Opción HOTFILL", "Hasta 40 bar de presión de soplado para los mayores desafíos"],
    "bbmppc21": "La herramienta más eficiente para sustituir las máquinas semiautomáticas.",
    "bbmppc22": "En el diseño de nuestras máquinas de soplado de botellas de la serie economy, nos enfocamos en la eficiencia energética y la sostenibilidad, manteniendo al mismo tiempo el máximo nivel de calidad y operación confiable característicos de nuestra producción.",
    "bbmppc23": "POLIOL Finance™ Opciones de alquiler: Opciones de alquiler flexibles según sus necesidades financieras.",
    "bbmppc24": ["Funcionamiento totalmente automático", "Bajo consumo de energía", "Sistema de recuperación de aire", "Diseño compacto", "Instalación rápida y sencilla"],
    "bph": " bph",
    "pp1": "Características",
    "pp2": "Solicitud de oferta",
    "pp3": "Uso recomendado",
    "pp4": "Galería",
    "prodGroupLocale": [
    {
    "id": "buckets",
    "name": "Accesorios",
    "types": [
      {
        "id": "faipar",
        "name": "Industria maderera, Agricultura",
        "subtitle": "Cucharas y accesorios duraderos y eficientes para la industria maderera y la agricultura. 30 años de experiencia, alta capacidad de diseño y fabricación."
      },
      {
        "id": "foldmunka",
        "name": "Gran volumen de movimiento de tierras",
        "subtitle": "Cucharas y accesorios fuertes para obras de movimiento de tierras de gran volumen. ¡Mejore su eficiencia y minimice el tiempo de inactividad!"
      },
      {
        "id": "kozmu",
        "name": "Construcción de servicios públicos",
        "subtitle": "Herramientas confiables para la construcción de servicios públicos. ¡Haga su pedido hoy y aproveche nuestro servicio de entrega rápida!"
      },
      {
        "id": "varos",
        "name": "Mantenimiento de la ciudad",
        "subtitle": "Cucharas y accesorios profesionales para un mantenimiento de la ciudad eficiente. ¡Planificamos, fabricamos y ofrecemos confiabilidad!"
      },
      {
        "id": "banyaszat",
        "name": "Minería",
        "subtitle": "Herramientas fabricadas con material Hardox para las necesidades de la minería de piedra y roca. Descubra nuestros productos que soportan incluso las condiciones más duras."
      },
      {
        "id": "gyorscserelok",
        "name": "Cambiadores rápidos de accesorios",
        "subtitle": "Cambiadores rápidos y confiables de accesorios para la máxima eficiencia. ¡Elija entre nuestras soluciones innovadoras o diseñemos algo completamente personalizado juntos!"
      }
    ]
    },
    {
      "id": "bottles",
      "name": "Botellas",
      "types": [
        {
          "id": "vegyipar",
          "image": "vegyipar.webp",
          "name": "Industria química",
          "subtitle": "Botellas PET duraderas y seguras para almacenar productos químicos y detergentes."
        },
        {
          "id": "elelmiszeripar",
          "image": "Uditogyartas.webp",
          "name": "Industria alimentaria",
          "subtitle": "Botellas PET higiénicas y seguras que preservan la frescura y calidad de los alimentos."
        },
        {
          "id": "asvanyi-olaj-kenoanyag",
          "image": "Olajuzemek.webp",
          "name": "Aceite mineral, lubricante",
          "subtitle": "Botellas PET resistentes que almacenan y transportan con seguridad aceites minerales y lubricantes."
        },
        {
          "id": "kozmetikai",
          "image": "Kozmetikai-cegek.webp",
          "name": "Cosméticos",
          "subtitle": "Botellas PET elegantes y duraderas que preservan la frescura y calidad de los productos cosméticos."
        }
      ]
    }
],
      "prodLocale": [
        {
          "id": "rezsu-kanal",
          "name": "Pala de zanjas",
          "properties": [
            "Cuchilla HARDOX 500",
            "Perfil de pala robusto y de diseño único",
            "Disponible en varios perfiles"
          ],
          "usage": "Recomendado para limpiar canales y zanjas, y para trabajos de nivelación.",
          "text": "La pala fija para zanjas es una solución perfecta para trabajos de movimiento de tierra, especialmente para la formación de canales, pendientes y zanjas. La herramienta es de alta calidad y posee una excelente resistencia al desgaste, garantizando una larga vida útil. Permite un trabajo más preciso y eficiente. ¡Elija la pala fija para zanjas para trabajos de tierra precisos y confiables!"
        },
        {
          "id": "hidraulikus-rezsu-kanal",
          "name": "Pala de zanjas inclinable hidráulica",
          "properties": [
            "Cuchilla y borde lateral HARDOX 500",
            "Cuerpo de pala robusto de acero S355",
            "Varios refuerzos de arco",
            "Perfil de pala moderno",
            "Superficie de nivelación extendida sin costo adicional",
            "Trabajo preciso con flujo ajustable para proteger los cilindros y garantizar una operación precisa"
          ],
          "usage": "Recomendado para todas las tareas de nivelación y limpieza de zanjas donde el terreno irregular no puede ser eliminado por la máquina.",
          "text": "La pala de zanjas hidráulica revoluciona la formación de zanjas y canales, permitiendo un trabajo preciso y eficiente en cualquier lugar de trabajo. El innovador sistema hidráulico permite una maniobrabilidad fácil y precisa, así como un rendimiento de movimiento de tierras de alto rendimiento para el operador de la máquina. La pala, fabricada con materiales de alta calidad, garantiza una excelente resistencia al desgaste y una larga vida útil para un uso confiable y duradero.\n ¡Elija la versión hidráulica para una amplia gama de aplicaciones y para trabajos de tierra eficientes y flexibles! Confíe en una herramienta que representa la cima del rendimiento y la fiabilidad en cualquier lugar de trabajo."
        },
        {
          "id": "melyaso-kanal-korom-nelkul",
          "name": "Pala de excavación profunda con cuchilla",
          "properties": [
            "Perfil de pala diseñado basado en los comentarios de los operadores de máquinas",
            "Cuchilla y borde lateral HARDOX 500",
            "Disponible en versiones estándar, reforzada y extrarreforzada",
            "Para todos los tipos de excavadoras"
          ],
          "usage": "Recomendado para la mayoría de las excavaciones subterráneas y la carga.",
          "text": "La pala de excavación profunda es ideal para excavaciones profundas, como la creación de cimientos y zanjas. Esta herramienta, fabricada según necesidades específicas, es de alta calidad, confiable y duradera. Una excelente opción para trabajos de movimiento de tierra precisos y eficientes. ¡Elija la pala de excavación profunda para un trabajo profesional y los mejores resultados!"
        },
        {
          "id": "rezsu-kanal-varos",
          "name": "Pala de zanjas",
          "properties": [
            "Cuchilla HARDOX 500",
            "Perfil de pala robusto y de diseño único",
            "Disponible en varios perfiles"
          ],
          "usage": "Recomendado para limpiar canales y zanjas, y para trabajos de nivelación.",
          "text": "La pala fija para zanjas es una solución perfecta para trabajos de movimiento de tierra, especialmente para la formación de canales, pendientes y zanjas. La herramienta es de alta calidad y posee una excelente resistencia al desgaste, garantizando una larga vida útil. Permite un trabajo más preciso y eficiente. ¡Elija la pala fija para zanjas para trabajos de tierra precisos y confiables!"
        },
        {
          "id": "melyaso-kanal",
          "name": "Pala de excavación profunda con dientes",
          "properties": [
            "Perfil de pala diseñado basado en los comentarios de los operadores de máquinas",
            "Cuchilla y borde lateral HARDOX 500",
            "Disponible con sistema de dientes opcional",
            "Resistente a la abrasión con tiras de refuerzo en la placa de la pala",
            "Disponible en versiones estándar, reforzada y extrarreforzada",
            "Para todos los tipos de excavadoras"
          ],
          "usage": "Recomendado para la mayoría de las excavaciones subterráneas y la carga.",
          "text": "La pala de excavación profunda es ideal para excavaciones profundas, como la creación de cimientos y zanjas. Esta herramienta, fabricada según necesidades específicas, es de alta calidad, confiable y duradera. Una excelente opción para trabajos de movimiento de tierra precisos y eficientes. ¡Elija la pala de excavación profunda para un trabajo profesional y los mejores resultados!"
        },
        {
          "id": "trapez-kanal-foldmunka",
          "name": "Pala trapezoidal",
          "properties": [
            "Cuchilla 500 HB",
            "Disponible con borde lateral hidráulicamente inclinable y ajustable",
            "Perfil diseñado basado en los comentarios de los operadores de máquinas"
          ],
          "usage": "Recomendado para la excavación de zanjas - Para perfiles de zanja simétricos y asimétricos que no requieren trabajo posterior",
          "text": "Descubra la versatilidad de la pala trapezoidal, que ofrece soluciones ideales para la excavación, formación y limpieza de zanjas, así como para el mantenimiento de canales. Fabricada con materiales de alta calidad y equipada con una excelente resistencia al desgaste, garantiza una larga vida útil y fiabilidad durante el proceso de trabajo. Mejore el nivel de sus trabajos con la pala trapezoidal, que permite resultados más precisos y eficientes en todas las condiciones. ¡La mejor opción cuando la calidad y el rendimiento importan!"
        },
        {
          "id": "melyaso-kanal-korom-nelkul-kozmu",
          "name": "Pala de excavación profunda con cuchilla",
          "properties": [
            "Perfil de pala diseñado basado en los comentarios de los operadores de máquinas",
            "Cuchilla y borde lateral HARDOX 500",
            "Disponible en versiones estándar, reforzada y extrarreforzada",
            "Para todos los tipos de excavadoras"
          ],
          "usage": "Recomendado para la mayoría de las excavaciones subterráneas y la carga.",
          "text": "La pala de excavación profunda es ideal para excavaciones profundas, como la creación de cimientos y zanjas. Esta herramienta, fabricada según necesidades específicas, es de alta calidad, confiable y duradera. Una excelente opción para trabajos de movimiento de tierra precisos y eficientes. ¡Elija la pala de excavación profunda para un trabajo profesional y los mejores resultados!"
        },
        {
          "id": "trapez-kanal-kozmuepites",
          "name": "Pala trapezoidal",
          "properties": [
            "Cuchilla 500 HB",
            "Disponible con borde lateral hidráulicamente inclinable y ajustable",
            "Perfil diseñado basado en los comentarios de los operadores de máquinas"
          ],
          "usage": "Recomendado para la excavación de zanjas - Para perfiles de zanja simétricos y asimétricos que no requieren trabajo posterior",
          "text": "Descubra la versatilidad de la pala trapezoidal, que ofrece soluciones ideales para la excavación, formación y limpieza de zanjas, así como para el mantenimiento de canales. Fabricada con materiales de alta calidad y equipada con una excelente resistencia al desgaste, garantiza una larga vida útil y fiabilidad durante el proceso de trabajo. Mejore el nivel de sus trabajos con la pala trapezoidal, que permite resultados más precisos y eficientes en todas las condiciones. ¡La mejor opción cuando la calidad y el rendimiento importan!"
        },
        {
          "id": "ripper",
          "name": "Diente de desgarrador",
          "properties": [
            "Cuerpo de acero estructural S355",
            "Revestimiento de HARDOX 500",
            "Sistema de dientes fácilmente reemplazable",
            "Diseñado para mantenerse afilado constantemente",
            "Compatible con cualquier tipo de acoplamiento"
          ],
          "usage": "Recomendado para aflojar suelos duros y salinos, arrancar tocones y voltear materiales incrustados en el suelo.",
          "text": "Descubra la versatilidad del diente de desgarrador, una herramienta fundamental tanto para mini máquinas como para las excavadoras de orugas más grandes. Esta innovadora herramienta permite aflojar suelos duros y salinos, lo que facilita la carga de los materiales. Use una solución que garantiza eficiencia y resultados en cada lugar de trabajo."
        },
        {
          "id": "hidraulikus-rezsu-kanal-kozmu",
          "name": "Pala de zanjas inclinable hidráulica",
          "properties": [
            "Cuchilla y borde lateral HARDOX 500",
            "Cuerpo de pala robusto de acero S355",
            "Varios refuerzos de arco",
            "Perfil de pala moderno",
            "Superficie de nivelación extendida sin costo adicional",
            "Trabajo preciso con flujo ajustable para proteger los cilindros y garantizar una operación precisa"
          ],
          "usage": "Recomendado para todas las tareas de nivelación y limpieza de zanjas donde el terreno irregular no puede ser eliminado por la máquina.",
          "text": "La pala de zanjas hidráulica revoluciona la formación de zanjas y canales, permitiendo un trabajo preciso y eficiente en cualquier lugar de trabajo. El innovador sistema hidráulico permite una maniobrabilidad fácil y precisa, así como un rendimiento de movimiento de tierras de alto rendimiento para el operador de la máquina. La pala, fabricada con materiales de alta calidad, garantiza una excelente resistencia al desgaste y una larga vida útil para un uso confiable y duradero.\n ¡Elija la versión hidráulica para una amplia gama de aplicaciones y para trabajos de tierra eficientes y flexibles! Confíe en una herramienta que representa la cima del rendimiento y la fiabilidad en cualquier lugar de trabajo."
        },
        {
          "id": "erositett-melyaso-kanal",
          "name": "Pala de minería",
          "properties": [
            "Cuerpo de pala de Hardox HiTuf 500",
            "Soldaduras resistentes a la abrasión con material de aporte especial",
            "Para las tareas más exigentes de minería",
            "Nivel de equipamiento personalizado"
          ],
          "usage": "Recomendado para las tareas y esfuerzos más exigentes de la minería. Solución a largo plazo disponible localmente en el país.",
          "text": "La pala de minería está diseñada específicamente para su uso en entornos de minería e industria pesada, para mover grandes cantidades de materiales. La herramienta es de alta calidad y posee una excelente resistencia al desgaste, garantizando una larga vida útil. Su eficacia ayuda a realizar tareas de manipulación de materiales más intensivas. ¡Elija nuestra pala de minería para una manipulación de materiales confiable y duradera!"
        },
        {
          "id": "erositett-rakodo-kanal",
          "name": "Pala de carga reforzada (HDX)",
          "properties": [
            "Diseñada para las tareas de carga más difíciles en minería",
            "Fabricada a medida para cualquier tipo de máquina",
            "Todas las piezas de la pala pueden equiparse con elementos y segmentos de desgaste",
            "Selección de perfil de pala y forma de cuchilla según preferencia"
          ],
          "usage": "Diseñada específicamente para tareas de carga en minería. Equipamiento personalizado según las necesidades del cliente.",
          "text": "Descubra la cima del rendimiento y la durabilidad con la pala de carga reforzada, diseñada específicamente para los desafíos más exigentes de la minería e industria. El diseño robusto y las piezas sobredimensionadas ofrecen un rendimiento excepcional incluso en las condiciones de trabajo más extremas. Las áreas sujetas a desgaste de la pala están protegidas con placas especiales resistentes a la abrasión, mientras que el borde lateral extrarreforzado garantiza la máxima protección y durabilidad."
        },
        {
          "id": "rakodo-kanal",
          "name": "Pala de carga",
          "properties": [
            "Para todo tipo de cargadoras",
            "Con cuerpo de pala HARDOX 500 opcional",
            "Disponible con conjunto de dientes en todos los tamaños",
            "Disponible en versión abierta y de descarga alta",
            "Fabricada en versiones estándar, reforzada y de minería"
          ],
          "usage": "Recomendado principalmente para tareas de carga desde pequeñas a grandes cantidades. Para todo tipo de máquinas. Con equipamiento de cuchilla o dientes según las características del material.",
          "text": "La pala de carga se utiliza de manera versátil para cargar y transportar materiales, como arena, grava y tierra. Gracias a la fabricación personalizada, está diseñada exactamente según sus necesidades, siendo una herramienta de alta calidad. Su fiabilidad y durabilidad aumentan la eficiencia del trabajo. ¡Elija nuestra pala de carga para todas sus tareas de carga!"
        },
        {
          "id": "ripper-banyaszat",
          "name": "Diente de desgarrador",
          "properties": [
            "Cuerpo de acero estructural S355",
            "Revestimiento de HARDOX 500",
            "Sistema de dientes fácilmente reemplazable",
            "Diseñado para mantenerse afilado constantemente",
            "Compatible con cualquier tipo de acoplamiento"
          ],
          "usage": "Recomendado para aflojar suelos duros y salinos, arrancar tocones y voltear materiales incrustados en el suelo.",
          "text": "Descubra la versatilidad del diente de desgarrador, una herramienta fundamental tanto para mini máquinas como para las excavadoras de orugas más grandes. Esta innovadora herramienta permite aflojar suelos duros y salinos, lo que facilita la carga de los materiales. Use una solución que garantiza eficiencia y resultados en cada lugar de trabajo."
        },
        {
          "id": "hidraulikus-kotro-XCMG-gyorscserelo",
          "name": "Cambio rápido de excavadora hidráulico / mecánico",
          "properties": [
            "Una herramienta clave para la versatilidad de la excavadora. Ahorro de tiempo y esfuerzo manual y mecánico, disponible en versión hidráulica y mecánica para todo tipo de máquinas",
            "Una herramienta clave para la versatilidad de la excavadora",
            "Ahorro de tiempo y esfuerzo humano y mecánico",
            "Disponible en versión hidráulica y mecánica",
            "Para todo tipo de máquinas"
          ],
          "usage": "Recomendado para excavadoras de uso múltiple donde se requiere un cambio frecuente de herramientas. En el mundo en desarrollo actual, es casi un equipo estándar para una excavadora.",
          "text": "El cambio rápido de excavadora hidráulico permite un cambio rápido de herramientas de excavación, lo que aumenta significativamente la versatilidad de la máquina. Esta herramienta, fabricada según necesidades específicas, es de alta calidad y duradera. Permite realizar diferentes trabajos de movimiento de tierras de manera más rápida y eficiente, todo al más alto nivel de eficiencia. ¡Elija el cambio rápido de excavadora hidráulico para un trabajo flexible y rápido!"
        },
        {
          "id": "hidraulikus-rakodo-VOLVO-gyorscserelo",
          "name": "Cambio rápido de cargadora hidráulico",
          "properties": [
            "Permite un uso multifuncional de la cargadora."
          ],
          "usage": "Recomendado para cargadoras de uso múltiple donde se requiere un cambio frecuente de herramientas.",
          "text": "Herramienta desarrollada para la creación de fosas de drenaje, canales y zanjas."
        },
        {
          "id": "balavilla",
          "name": "Pinza para pacas",
          "properties": [
            "Accesorio con un perfil de diseño propio",
            "Diseño simple y precio económico",
            "Con púas reemplazables",
            "Compatible con cualquier tipo de acoplamiento"
          ],
          "usage": "Recomendado para el sector agrícola para la carga de pacas.",
          "text": "La pinza para pacas es ideal para mover y cargar pacas de heno, paja y otros materiales agrícolas. Esta herramienta, fabricada según necesidades específicas, ofrece una solución confiable y duradera. Facilita y hace más eficiente la manipulación de pacas en su uso diario.",
          "noOfRefPics": 1
        },
        {
          "id": "tragyavilla",
          "name": "Pinza para estiércol",
          "properties": [
            "Estructura robusta y simple de acero estructural S355",
            "Con púas reemplazables",
            "Disponible en versión con garra de cocodrilo hidráulica",
            "Disponible en versión simple con púas inferiores"
          ],
          "usage": "Recomendado para trabajos agrícolas, carga de estiércol y carga de residuos vegetales fibrosos.",
          "text": "La pinza para estiércol es ideal para manejar y cargar estiércol en áreas agrícolas. Esta herramienta, fabricada según necesidades específicas, es de alta calidad, confiable y duradera. Permite realizar tareas de manipulación de estiércol de manera fácil y eficiente. Participe en el progreso de la mecanización agrícola eficiente."
        },
        {
          "id": "konnyuanyag-kanal",
          "name": "Pala para materiales ligeros",
          "properties": [
            "Perfil de pala anti-derrame, cuchilla Hardox 500, gran capacidad sin peso adicional, opción de cuchilla atornillable",
            "Perfil de pala anti-derrame",
            "Cuchilla HARDOX 500",
            "Gran capacidad sin peso adicional",
            "Opción de cuchilla atornillable"
          ],
          "usage": "Recomendado para la carga masiva de materiales agrícolas dispersos y piensos granulares, tanto en versiones fijas como de descarga alta.",
          "text": "La pala para materiales ligeros es ideal para cargar y mover materiales ligeros como virutas de madera, compost y desechos. La herramienta es de alta calidad y posee una excelente resistencia al desgaste, garantizando una larga vida útil. Es una elección perfecta para un trabajo eficiente y preciso. ¡Elija la pala para materiales ligeros para tareas de manipulación de materiales eficientes!"
        },
        {
          "id": "magasuritesu-kanal",
          "name": "Pala de descarga alta",
          "properties": [
            "Cuerpo de pala robusto",
            "Cuchilla HARDOX 500",
            "Diseño anti-derrame único",
            "Cálculo real de volumen"
          ],
          "usage": "Recomendado para tareas de descarga en altura.",
          "text": "La pala de descarga alta está diseñada para las tareas industriales más grandes, permitiendo el movimiento rápido y eficiente de grandes volúmenes de material. Su diseño robusto y estructura reforzada aseguran el máximo rendimiento incluso bajo las cargas más pesadas. Fabricada con materiales de alta calidad, la pala garantiza una excelente resistencia al desgaste y una larga vida útil durante el uso continuo.\n No deje que las grandes tareas lo detengan: elija la pala de descarga alta para eficiencia y confiabilidad. Impulse el rendimiento de su lugar de trabajo con una herramienta que asegura la mayor productividad y eficacia en todas las condiciones."
        },
        {
          "id": "raklapvilla",
          "name": "Horquilla para palés",
          "properties": [
            "Amplia gama de capacidad de carga",
            "Fabricada para todo tipo de máquinas, no solo para cargadoras",
            "Certificación CE"
          ],
          "usage": "Recomendado principalmente para mover mercancías en palés, así como cualquier otro objeto que cumpla con la capacidad de carga.",
          "text": "La horquilla para palés es ideal para mover y manipular palés y varias cargas de manera segura y eficiente. Esta herramienta es de alta calidad, confiable y duradera. Fabricada según necesidades específicas, facilita y hace más precisas las tareas de carga. ¡Elija la horquilla para palés para calidad y eficiencia excepcionales!"
        },
            {
              id: 'pap1eco',
              name: 'PAP-1 ECO',
              overlayText: 'La familia de máquinas PA, excepto por el estirado operado por servomotor, consta de máquinas operadas por cilindros neumáticos. Las máquinas PAP están diseñadas específicamente para producir botellas de pequeño volumen con mínimos requisitos de espacio y energía. Solo este tipo ECO puede pedirse en una configuración semiautomática, con alimentación manual de preformas y extracción manual de botellas. Es una máquina simple.'
            },
            {
              id: 'pap1',
              name: 'PAP-1',
              overlayText: 'La familia de máquinas PA, excepto por el estirado operado por servomotor, consta de máquinas operadas por cilindros neumáticos. Las máquinas PAP están diseñadas específicamente para producir botellas de pequeño volumen con mínimos requisitos de espacio y energía. Este tipo es completamente automático. El control de la máquina es capaz de servir a una máquina de llenado en modo en línea, colocando las botellas terminadas en una cinta transportadora con una unidad adicional.'
            },
            {
              id: 'pap1hotFill',
              name: 'PAP-1 HOT FILL',
              overlayText: 'La máquina PAP-1 está equipada con un paquete adicional que permite la producción de botellas rellenables en caliente. La máquina puede cambiar fácilmente entre la producción de botellas HOTFILL o convencionales.'
            },
            {
              id: 'pa1eco',
              name: 'PA-1 ECO',
              overlayText: 'La familia de máquinas PA, excepto por el estirado operado por servomotor, consta de máquinas operadas por cilindros neumáticos. Este tipo es completamente automático. El control de la máquina es capaz de servir a una máquina de llenado en modo en línea, colocando las botellas terminadas en una cinta transportadora con una unidad adicional. Es una máquina simple.'
            },
            {
              id: 'pa1hotFill',
              name: 'PA-1 HOT FILL',
              overlayText: 'La máquina PA-1 ECO está equipada con un paquete adicional que permite la producción de botellas rellenables en caliente. La máquina puede cambiar fácilmente entre la producción de botellas HOTFILL o convencionales.'
            },
            {
              id: 'pe15uni',
              name: 'PE-15 UNI',
              overlayText: 'Todos los movimientos principales de la familia de máquinas PE son accionados por servomotores, lo que permite velocidades rápidas de movimiento que acortan el tiempo de ciclo y aumentan la productividad. La familia de máquinas está construida utilizando los componentes electrónicos y de transmisión de datos más modernos, lo que permite configuraciones de movimiento y tecnología muy precisas con retroalimentación controlada. La familia PE se caracteriza por su alta eficiencia energética y un consumo de aire minimizado gracias a varios sistemas de recuperación de aire. Las botellas salen de la máquina a través de transportadores de aire, y el control es adecuado para la operación en línea con máquinas de llenado. Los cambios rápidos de producto y preforma son apoyados por muchas soluciones integradas y opcionales.'
            },
            {
              id: 'pe110uni',
              name: 'PE-110 UNI',
              overlayText: 'Todos los movimientos principales de la familia de máquinas PE son accionados por servomotores, lo que permite velocidades rápidas de movimiento que acortan el tiempo de ciclo y aumentan la productividad. La familia de máquinas está construida utilizando los componentes electrónicos y de transmisión de datos más modernos, lo que permite configuraciones de movimiento y tecnología muy precisas con retroalimentación controlada. La familia PE se caracteriza por su alta eficiencia energética y un consumo de aire minimizado gracias a varios sistemas de recuperación de aire. Las botellas salen de la máquina a través de transportadores de aire, y el control es adecuado para la operación en línea con máquinas de llenado. La máquina está desarrollada especialmente para la producción de botellas de mayor volumen.'
            },
            {
              id: 'pap2',
              name: 'PAP-2',
              overlayText: 'La familia de máquinas PA, excepto por el estirado operado por servomotor, consta de máquinas operadas por cilindros neumáticos. Las máquinas PAP están diseñadas específicamente para producir botellas de pequeño volumen con mínimos requisitos de espacio y energía. Este tipo es completamente automático. El control de la máquina es capaz de servir a una máquina de llenado en modo en línea, colocando las botellas terminadas en una cinta transportadora con una unidad adicional. La máquina tiene el mismo tamaño que el PAP-1 pero puede producir dos botellas más pequeñas a la vez.'
            },
            {
              id: 'pa2ecoplus',
              name: 'PA-2 ECO+',
              overlayText: 'La familia de máquinas PA, excepto por el estirado operado por servomotor, consta de máquinas operadas por cilindros neumáticos. Este tipo es completamente automático. El control de la máquina es capaz de servir a una máquina de llenado en modo en línea. Las botellas salen de la máquina a través de transportadores de aire, y el control es adecuado para la operación en línea con máquinas de llenado. La versión ECO+ es el modelo modernizado y eficiente energéticamente de las máquinas operadas neumáticamente.'
            },
            {
              id: 'pe2uni',
              name: 'PE-2 UNI',
              overlayText: 'Todos los movimientos principales de la familia de máquinas PE son accionados por servomotores, lo que permite velocidades rápidas de movimiento que acortan el tiempo de ciclo y aumentan la productividad. La familia de máquinas está construida utilizando los componentes electrónicos y de transmisión de datos más modernos, lo que permite configuraciones de movimiento y tecnología muy precisas con retroalimentación controlada. La familia PE se caracteriza por su alta eficiencia energética y un consumo de aire minimizado gracias a varios sistemas de recuperación de aire. Las botellas salen de la máquina a través de transportadores de aire, y el control es adecuado para la operación en línea con máquinas de llenado. Los cambios rápidos de producto y preforma son apoyados por muchas soluciones integradas y opcionales.'
            },
            {
              id: 'pe2uniHotFill',
              name: 'PE-2 UNI HOT FILL',
              overlayText: 'La máquina PE-2 está equipada con un paquete adicional que permite la producción de botellas rellenables en caliente. La máquina puede cambiar fácilmente entre la producción de botellas HOTFILL o convencionales.'
            },
            {
              id: 'pe25uni',
              name: 'PE-25 UNI',
              overlayText: 'Todos los movimientos principales de la familia de máquinas PE son accionados por servomotores, lo que permite velocidades rápidas de movimiento que acortan el tiempo de ciclo y aumentan la productividad. La familia de máquinas está construida utilizando los componentes electrónicos y de transmisión de datos más modernos, lo que permite configuraciones de movimiento y tecnología muy precisas con retroalimentación controlada. La familia PE se caracteriza por su alta eficiencia energética y un consumo de aire minimizado gracias a varios sistemas de recuperación de aire. Las botellas salen de la máquina a través de transportadores de aire, y el control es adecuado para la operación en línea con máquinas de llenado. Los cambios rápidos de producto y preforma son apoyados por muchas soluciones integradas y opcionales.'
            },
            {
              id: 'pa210uni',
              name: 'PA-210 UNI',
              overlayText: 'Todos los movimientos principales de la familia de máquinas PE son accionados por servomotores, lo que permite velocidades rápidas de movimiento que acortan el tiempo de ciclo y aumentan la productividad. La familia de máquinas está construida utilizando los componentes electrónicos y de transmisión de datos más modernos, lo que permite configuraciones de movimiento y tecnología muy precisas con retroalimentación controlada. La familia PE se caracteriza por su alta eficiencia energética y un consumo de aire minimizado gracias a varios sistemas de recuperación de aire. Las botellas salen de la máquina a través de transportadores de aire, y el control es adecuado para la operación en línea con máquinas de llenado. Los cambios rápidos de producto y preforma son apoyados por muchas soluciones integradas y opcionales.'
            },
            {
              id: 'pe4uni',
              name: 'PE-4 UNI',
              overlayText: 'Todos los movimientos principales de la familia de máquinas PE son accionados por servomotores, lo que permite velocidades rápidas de movimiento que acortan el tiempo de ciclo y aumentan la productividad. La familia de máquinas está construida utilizando los componentes electrónicos y de transmisión de datos más modernos, lo que permite configuraciones de movimiento y tecnología muy precisas con retroalimentación controlada. La familia PE se caracteriza por su alta eficiencia energética y un consumo de aire minimizado gracias a varios sistemas de recuperación de aire. Las botellas salen de la máquina a través de transportadores de aire, y el control es adecuado para la operación en línea con máquinas de llenado. Los cambios rápidos de producto y preforma son apoyados por muchas soluciones integradas y opcionales.'
            },
            {
              id: 'pe6uni',
              name: 'PE-6 UNI',
              overlayText: 'Todos los movimientos principales de la familia de máquinas PE son accionados por servomotores, lo que permite velocidades rápidas de movimiento que acortan el tiempo de ciclo y aumentan la productividad. La familia de máquinas está construida utilizando los componentes electrónicos y de transmisión de datos más modernos, lo que permite configuraciones de movimiento y tecnología muy precisas con retroalimentación controlada. La familia PE se caracteriza por su alta eficiencia energética y un consumo de aire minimizado gracias a varios sistemas de recuperación de aire. Las botellas salen de la máquina a través de transportadores de aire, y el control es adecuado para la operación en línea con máquinas de llenado. Los cambios rápidos de producto y preforma son apoyados por muchas soluciones integradas y opcionales.'
            },
            {
              id: 'pe12uni',
              name: 'PE-12 UNI',
              overlayText: 'Todos los movimientos principales de la familia de máquinas PE son accionados por servomotores, lo que permite velocidades rápidas de movimiento que acortan el tiempo de ciclo y aumentan la productividad. La familia de máquinas está construida utilizando los componentes electrónicos y de transmisión de datos más modernos, lo que permite configuraciones de movimiento y tecnología muy precisas con retroalimentación controlada. El modelo PE-12 está desarrollado específicamente para la producción de botellas de pequeño volumen. Las botellas salen de la máquina a través de transportadores de aire, y el control es adecuado para la operación en línea con máquinas de llenado.'
            }
          ],
          
      //IMG ALTS
      //#region
      imgAlt0:'Poliol Logo',
      imgAlt1:'Markoló üríti rakományát.',
      imgAlt2:'Flakonfúvó gép váz rajz',
      imgAlt3:'Víz fröcsköl egy vizesflakonból',
      imgAlt4:'Világtérkép',
      imgAlt5:'Markoló kanállal',
      imgAlt6:'Hegesztés',
      imgAlt7:'Gyártott bányakanál',
      imgAlt8:'Robothegesztés',
      imgAlt9:'Fehér flakonok sorakoznak',
      imgAlt10:'Alakadó szerszám gyártása',
      imgAlt11:'Flakon tervezése',
      imgAlt12:'Futurisztikus vizes flakon terv koncepció',
      imgAlt13:'',
      imgAlt14:'',
      imgAlt15:'',
      imgAlt16:'',
      imgAlt17:'',
      imgAlt18:'',
      imgAlt19:'',
      imgAlt20:'',
      imgAlt21:'',
      imgAlt22:'',
      //#endregion
      //PRIVACY POLICY, DATA PROTECTION
      //#region
      dp1:'Política de Privacidad',
      dp2:'Política de Privacidad, Protección de Datos, documento RGPD',
      cp1:'Política de quejas'
      //#endregion
    }